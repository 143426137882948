import Konva from "konva";
import { useEffect, useRef, useState } from "react";
import { Layer, Rect } from "react-konva";
import {
	AnnotationType,
	Position,
	RectangleAnnotation,
} from "../../../../lib/model/models";
import { useAppStore } from "../../../../lib/store/app_store";
import { GetLogger } from "../../../../lib/util/simple_logger";
import { useUIStageProps } from "../annotation/annotation_helper_util";
import { UIRectangleAnnotationDefaultStyleProps } from "../annotation/ui_rectangle_annotation";
import { UICanvasUtil } from "../ui_canvas_util";

const logger = GetLogger("ui_annotating_rectangle_activity_layer");

/**
 * Reverse coordinates if dragged left or up
 * @param {Object} r1 - Coordinates of the starting position of cropping rectangle
 * @param {Object} r2 - Coordinates of the current position of cropping rectangle
 */
function reverse(r1: Position, r2: Position) {
	let r1x = r1.x,
		r1y = r1.y,
		r2x = r2.x,
		r2y = r2.y,
		d;
	if (r1x > r2x) {
		d = Math.abs(r1x - r2x);
		r1x = r2x;
		r2x = r1x + d;
	}
	if (r1y > r2y) {
		d = Math.abs(r1y - r2y);
		r1y = r2y;
		r2y = r1y + d;
	}
	return { x1: r1x, y1: r1y, x2: r2x, y2: r2y }; // return the corrected rect.
}

interface UIAnnotatingRectangleActivityLayerProps {}
export const UIAnnotatingRectangleActivityLayer: React.FC<
	UIAnnotatingRectangleActivityLayerProps
> = (props) => {
	const store_imageSize = useAppStore((state) => state.imageSize);
	const store_annotations = useAppStore((state) => state.annotations);
	const store_updateAnnotations = useAppStore(
		(state) => state.updateAnnotations
	);

	// Activity Data;
	const [state_mode, state_setMode] = useState<"idle" | "drawing">("idle");
	const [state_posStart, state_setPosStart] = useState<Position>({
		x: 0,
		y: 0,
	});
	const uiStageProps = useUIStageProps();

	// Refs
	const state_layerRef = useRef<Konva.Layer>(null);
	const state_baseRectangleRef = useRef<Konva.Rect>(null);
	const state_currentRectangleRef = useRef<Konva.Rect>(null);

	const handleLayerOnMouseDown = (e: Konva.KonvaEventObject<MouseEvent>) => {
		logger.log("handleGraphicsLayerOnMouseDown");
		const position = UICanvasUtil.getMousePosition(e, state_layerRef.current);
		// Reset current rectangle
		state_currentRectangleRef?.current?.x(position.x);
		state_currentRectangleRef?.current?.y(position.y);
		state_currentRectangleRef?.current?.width(0);
		state_currentRectangleRef?.current?.height(0);

		logger.log("Current Rectangle Ref: ", state_currentRectangleRef);
		logger.log("positoin", position);
		state_setPosStart(position);
		state_setMode("drawing");
		// @ts-ignore
	};

	const updateDrag = (posIn: Position) => {
		let posRect = reverse(state_posStart!, posIn);

		state_currentRectangleRef?.current?.x(posRect.x1);
		state_currentRectangleRef?.current?.y(posRect.y1);
		state_currentRectangleRef?.current?.width(posRect.x2 - posRect.x1);
		state_currentRectangleRef?.current?.height(posRect.y2 - posRect.y1);

		logger.log("updated rect", [posRect, state_currentRectangleRef]);
	};

	const handleGraphicsLayerOnMouseMove = (
		e: Konva.KonvaEventObject<MouseEvent>
	) => {
		if (state_mode === "drawing") {
			logger.log("moving ", [state_mode, state_currentRectangleRef]);

			const position = UICanvasUtil.getMousePosition(e, state_layerRef.current);
			updateDrag(position);
		}
	};

	const handleGraphicsLayerOnMouseUp = (
		e: Konva.KonvaEventObject<MouseEvent>
	) => {
		let nextId =
			Object.keys(store_annotations).reduce(
				(maxv, id) => Math.max(maxv, parseInt(id)),
				0
			) + 1;
		let annotation: RectangleAnnotation = {
			annotationId: `${nextId}`,
			type: AnnotationType.rectangle,
			x: state_currentRectangleRef!.current!.x(),
			y: state_currentRectangleRef!.current!.y(),
			width: state_currentRectangleRef!.current!.width(),
			height: state_currentRectangleRef!.current!.height(),
			rotation: 0,
		};
		store_updateAnnotations([annotation]);
		state_setMode("idle");
	};

	const initialize = () => {
		logger.log("Initializing Rect", state_baseRectangleRef);
		// TODO: Modify store to change canvas statefor cursor.
		state_setPosStart({ x: 0, y: 0 });
		uiStageProps.pushNewStoreStageProps({ cursor: "crosshair" });
	};

	useEffect(() => {
		logger.log("AnnotatingRectangle mounted");
		initialize();
	}, []);

	const canvasSize = UICanvasUtil.getUICanvasSizeFromImageSize(store_imageSize);

	return (
		<Layer
			ref={state_layerRef}
			onMouseDown={handleLayerOnMouseDown}
			onMouseMove={handleGraphicsLayerOnMouseMove}
			onMouseUp={handleGraphicsLayerOnMouseUp}
		>
			<Rect
				ref={state_baseRectangleRef}
				{...{
					x: 0,
					y: 0,
					width: canvasSize.width,
					height: canvasSize.height,
					fill: "green",
					opacity: 0,
				}}
			/>
			<Rect
				ref={state_currentRectangleRef}
				{...UIRectangleAnnotationDefaultStyleProps}
			/>
		</Layer>
	);
};
