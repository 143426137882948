import { ArrowBackIcon } from "@chakra-ui/icons";
import { Center, HStack, Text, VStack } from "@chakra-ui/react";
import { FullPageLayout } from "../../lib/components/layout";

const CTAChromeButton = () => {
	return (
		<a href="/">
			<HStack
				rounded="md"
				color="white"
				padding="8px"
				fontWeight={"semibold"}
				borderWidth="1px"
				borderColor="#3f5efb"
			>
				<ArrowBackIcon color="white" />
				<Text>Back to Home</Text>
			</HStack>
		</a>
	);
};

export const NotFoundPage = () => {
	return (
		<FullPageLayout>
			<Center
				h="100%"
				w="100%"
				background="linear-gradient(to right, #512da8, #711e72)"
			>
				<VStack
					color="white"
					spacing="10px"
					paddingLeft="20px"
					paddingRight="20px"
				>
					<Text fontSize="10em" fontWeight={"bold"}>
						404
					</Text>
					<Text fontSize="1.4em" textAlign={"center"}>
						It appears we can't find what you are looking for.
					</Text>
					<CTAChromeButton />
				</VStack>
			</Center>
		</FullPageLayout>
	);
};
