import { Box, Stack } from "@chakra-ui/react";

interface FullPageLayoutProps {
	children: any;
}
export const FullPageLayout: React.FC<FullPageLayoutProps> = (props) => {
	return (
		<Box
			position={"fixed"}
			background="inherit"
			top={0}
			left={0}
			display="block"
			// opacity={0.7}
			// bottom={0}
			// right={0}
			width="100%"
			height="100%"
			overflow="hidden"
		>
			{props.children}
		</Box>
	);
};
export const FullPageLayoutWithLogo: React.FC<FullPageLayoutProps> = (
	props
) => {
	return (
		<Box
			position={"absolute"}
			background={"white"} //
			top={0}
			left={0}
			width="100vw"
			height="100vh"
			padding="40px"
		>
			<Stack
				width="100%"
				height="100%" //
				spacing="40px"
				// paddingTop="20px"
				// background="yellow"
			>
				{/* <Logo /> */}
				<Box
					width="100%"
					height="100%"
					//
					// background={"yellow"}
				>
					{props.children}
				</Box>
			</Stack>
		</Box>
	);
};
