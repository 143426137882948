import Konva from "konva";
import React, { useCallback, useEffect } from "react";
import { Rect, Transformer } from "react-konva";
import { APPEARANCE } from "../../../../lib/appearance/appearance";
import {
	Position,
	RectangleAnnotation,
	Size,
} from "../../../../lib/model/models";
import { useAppStore } from "../../../../lib/store/app_store";
import { UIStageProps } from "../../../../lib/store/app_store.interface";
import { GetLogger } from "../../../../lib/util/simple_logger";
import { UICanvasUtil } from "../ui_canvas_util";
// import { getMousePosition } from "../ui_canvas_util";
import { useRegisterAnnotationLayerListener } from "./annotation_helper_util";

const logger = GetLogger("ui_rectangle_annotation");

interface UIRectangleAnnotationProps {
	annotation: RectangleAnnotation;
}

export const UIRectangleAnnotationDefaultStyleProps = {
	strokeWidth: 4,
	stroke: APPEARANCE.annotationPrimary,
	// fill: "red",
	opacity: 1,
	cornerRadius: 2,
};

// export const createUIRectangleAnnotationObject = (
// 	pos: Position
// ): Konva.Rect => {
// 	return new Konva.Rect({
// 		x: pos.x,
// 		y: pos.y,
// 		width: 0,
// 		height: 0,
// 		// stroke: "red",
// 		// dash: [5, 10],
// 		visible: true,
// 		listening: false,
// 		...UIRectangleAnnotationDefaultStyleProps,
// 	});
// };

export const UIRectangleAnnotation: React.FC<UIRectangleAnnotationProps> = (
	props
) => {
	const store_updateAnnotations = useAppStore(
		(state) => state.updateAnnotations
	);
	const store_removeAnnotation = useAppStore((state) => state.removeAnnotation);
	const store_uiStageProps = useAppStore((state) => state.uiStageProps);
	const store_uiCanvasAnnotationLayerRef = useAppStore(
		(state) => state.uiCanvasAnnotationLayerRef
	);
	const store_updateUIStageProps = useAppStore(
		(state) => state.updateUIStageProps
	);
	const [state_prevUIStageProps, state_setPrevUIStageProps] =
		React.useState<UIStageProps | null>(store_uiStageProps);
	const state_rectRef = React.useRef<Konva.Rect>(null);
	// const [state_isClicked, state_setIsClicked] = React.useState(false);
	const [state_isSelected, state_setIsSelected] = React.useState(false);
	const state_transformerRef = React.useRef<Konva.Transformer>(null);

	const handleOnClickOutside = useCallback(
		(e: Konva.KonvaEventObject<MouseEvent>) => {
			// if (state_groupRef.current.)
			// logger.log("clicking outside...", e.currentTarget);
			// get mouseposition in the layer.
			const mousePosition = UICanvasUtil.getMousePosition(
				e,
				store_uiCanvasAnnotationLayerRef?.current
			);

			const boundingRectPos: Position = {
				x: state_rectRef?.current?.x() ?? 0,
				y: state_rectRef?.current?.y() ?? 0,
			};
			const boundingRectSize: Size = {
				width: state_rectRef?.current?.width() ?? 0,
				height: state_rectRef?.current?.height() ?? 0,
			};
			// if the mouse pointer is outside this, then we can deselect.
			const x1 = boundingRectPos.x;
			const y1 = boundingRectPos.y;
			const x2 = boundingRectPos.x + boundingRectSize.width;
			const y2 = boundingRectPos.y + boundingRectSize.height;
			const isInside =
				mousePosition.x >= x1 &&
				mousePosition.x <= x2 &&
				mousePosition.y >= y1 &&
				mousePosition.y <= y2;

			if (!isInside && state_isSelected) {
				logger.log("clicking outside...", e.currentTarget);
				state_setIsSelected(false);
				// state_setIsEditorEnabled(false);
			}
		},
		[
			state_isSelected,
			state_setIsSelected,
			store_uiCanvasAnnotationLayerRef,
			state_rectRef,
		]
	);

	const handleOnKeyDownOutside = useCallback(
		(e: KeyboardEvent) => {
			logger.log("key down outside...", e.key);
			if (e.key === "Backspace" && state_isSelected) {
				logger.log("deleting annotation...", props.annotation.annotationId);
				store_removeAnnotation(props.annotation.annotationId);
			}
		},
		[store_removeAnnotation, state_isSelected, props.annotation.annotationId]
	);

	const handleOnDragEnd = (e: Konva.KonvaEventObject<DragEvent>) => {
		logger.log("drag ended");
		store_updateAnnotations([
			{
				...props.annotation,
				x: e.target.x(),
				y: e.target.y(),
			} as RectangleAnnotation,
		]);
	};
	const handleOnDragStart = (e: Konva.KonvaEventObject<DragEvent>) => {};
	const handleOnMouseEnter = (e: Konva.KonvaEventObject<MouseEvent>) => {
		state_setPrevUIStageProps(store_uiStageProps);
		store_updateUIStageProps({ cursor: "move" });

		logger.log("mouse enter");
	};
	const handleOnMouseLeave = (e: Konva.KonvaEventObject<MouseEvent>) => {
		if (state_prevUIStageProps) {
			store_updateUIStageProps(state_prevUIStageProps);
		}
	};

	const handleOnClick = (e: Konva.KonvaEventObject<MouseEvent>) => {
		state_setIsSelected(true);
	};

	// const handleOnTransform = (e: Konva.KonvaEventObject<DragEvent>) => {
	// 	// logger.log("here", e.target.rotation());
	// };

	const handleOnTransformEnd = (e: Konva.KonvaEventObject<Event>) => {
		store_updateAnnotations([
			{
				...props.annotation,
				rotation: e.target.rotation(),
				width: e.target.width(),
				height: e.target.height(),
			},
		]);
	};

	const handleOnDoubleClick = (e: Konva.KonvaEventObject<MouseEvent>) => {
		// state_setIsSelected(!state_isClicked);
		// logger.log("double click", state_isClicked);
	};

	useEffect(() => {
		if (state_rectRef?.current && state_transformerRef) {
			state_transformerRef.current?.nodes([state_rectRef?.current]);
			// state_transformerRef.current?.node
		}
	}, [state_rectRef, state_transformerRef]);

	// useEffect(() => {
	// 	// if (state_isSelected) {
	// 	logger.log("Registering Click");
	// 	store_uiCanvasAnnotationLayerRef?.current?.on(
	// 		"click",
	// 		handleOnClickOutside
	// 	);
	// 	const ref = store_uiCanvasAnnotationLayerRef?.current;
	// 	return () => {
	// 		logger.log("removing ...");
	// 		ref?.off("click", handleOnClickOutside);
	// 	};
	// }, [store_uiCanvasAnnotationLayerRef, handleOnClickOutside]);
	useRegisterAnnotationLayerListener({
		handleOnClickOutside,
		handleOnKeyDown: handleOnKeyDownOutside,
	});

	return (
		<React.Fragment>
			<Rect
				ref={state_rectRef}
				listening={true}
				x={props.annotation.x}
				y={props.annotation.y}
				width={props.annotation.width}
				height={props.annotation.height}
				{...UIRectangleAnnotationDefaultStyleProps}
				draggable
				onClick={handleOnClick}
				onDblClick={handleOnDoubleClick}
				onMouseEnter={handleOnMouseEnter}
				onMouseLeave={handleOnMouseLeave}
				onDragStart={handleOnDragStart}
				// onDragMove={handleOnDragMove}
				onDragEnd={handleOnDragEnd}
				// onTransform={handleOnTransform}
				onTransformEnd={handleOnTransformEnd}
				strokeScaleEnabled={false}
			/>
			<Transformer
				ref={state_transformerRef}
				visible={state_isSelected}
				ignoreStroke
				enabledAnchors={[
					"middle-left",
					"middle-right",
					"top-center",
					"bottom-center",
				]}
				boundBoxFunc={(oldBox: any, newBox: any) => {
					newBox.width = Math.max(30, newBox.width);
					return newBox;
				}}
			/>
		</React.Fragment>
	);
};
