import { Screenshot } from "../../lib/model/models";
import axios from "axios";
import { Config } from "../../lib/config/config";
import { GetLogger } from "../../lib/util/simple_logger";
import {
	MakeErrorStatus,
	MakeErrorStatusOr,
	OkStatusOr,
	StatusOr,
} from "../../lib/util/status";
import { ERROR_REASON_SIZE_LIMIT_EXCEEDED } from "./model";

const logger = GetLogger("api");

interface ErrorResponse {
	status: "ok" | "fail";
	reason: string;
}

// axios.defaults.baseURL = Config.API_BASE_URL;

// interface GetScreenshotResponse {
// 	screenshot: Screenshot;
// 	status: "ok" | "error";
// }

const putScreenshot = async (
	screenshot: Screenshot
): Promise<StatusOr<Screenshot>> => {
	interface Request {
		screenshot: Screenshot;
	}

	interface PutScreenshotResponse {
		screenshot: Screenshot;
		status: "ok" | "error";
	}

	const request: Request = {
		screenshot,
	};
	try {
		const url = `${Config.API_BASE_URL}/v1/screenshot`;
		logger.log("using url ", url);
		console.log("using url ", url);
		const response = await axios.post(url, request);
		const data: PutScreenshotResponse = response.data;
		if (data.status === "ok") {
			return OkStatusOr(data.screenshot);
		}
	} catch (e: any) {
		const data: ErrorResponse | undefined = e.response?.data as ErrorResponse;
		const reason = data?.reason ?? "unknown";
		let reasonToUse = "unknown";
		if (reason === ERROR_REASON_SIZE_LIMIT_EXCEEDED) {
			reasonToUse = ERROR_REASON_SIZE_LIMIT_EXCEEDED;
		}
		logger.error("Error occured", e.response.data.reason);
		return MakeErrorStatusOr(reasonToUse);
	}
	return MakeErrorStatusOr("unknown");
};

export const Api = { putScreenshot };
