import Konva from "konva";
import { Position, Size } from "../../../lib/model/models";
import { UI_CANVAS_CONTANTS } from "./ui_canvas_contants";

const getMousePosition = (
	e: Konva.KonvaEventObject<MouseEvent>,
	ref: Konva.Layer | null | undefined
): Position => {
	// Note: We could use [e.evt.layerX, e.evt.layerY], but the type doesn't appear
	// in the KonvaEventObject. So we use the relative pointer position instead.
	const cp = ref?.getRelativePointerPosition();
	return { x: cp?.x ?? 0, y: cp?.y ?? 0 };
};

const getUICanvasSizeFromImageSize = (imageSize: Size): Size => {
	return {
		width: imageSize.width * UI_CANVAS_CONTANTS.CANVAS_SIZE_MULTIPLIER,
		height: imageSize.height * UI_CANVAS_CONTANTS.CANVAS_SIZE_MULTIPLIER,
	};
};

export const UICanvasUtil = {
	getMousePosition,
	getUICanvasSizeFromImageSize,
};
