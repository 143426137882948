import { Config } from "../config/config";

class SimpleLogger {
	base: string;
	showLog: boolean;
	constructor(base: string, showLog: boolean = true) {
		this.base = base;
		this.showLog = showLog;
	}
	makeKey(key: string) {
		if (typeof key === "string") {
			return `${this.base}:${key}`;
		}
		return key;
	}
	log(key: string | any, value: any = {}) {
		if (!this.showLog) {
			return;
		}
		console.log(this.makeKey(key), value);
	}
	error(key: string, value: any = undefined) {
		if (!this.showLog) {
			return;
		}
		console.error(this.makeKey(key), value);
	}
}

export const GetLogger = (base: string) => {
	const showLog: boolean = true; //Config.APP_LOG_TYPE === "all";
	return new SimpleLogger(base, showLog);
};
