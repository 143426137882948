import Konva from "konva";
import { useEffect, useRef, useState } from "react";
import { Arrow, Layer, Rect } from "react-konva";
import {
	AnnotationType,
	ArrowAnnotation,
	Position,
} from "../../../../lib/model/models";
import { useAppStore } from "../../../../lib/store/app_store";
import { GetLogger } from "../../../../lib/util/simple_logger";
import { useUIStageProps } from "../annotation/annotation_helper_util";
import { UIArrowAnnotationDefaultStyleProps } from "../annotation/ui_arrow_annotation";
import { UICanvasUtil } from "../ui_canvas_util";

const logger = GetLogger("ui_annotating_rectangle_activity_layer");

export const UIAnnotatingArrowActivityLayer = () => {
	const store_imageSize = useAppStore((state) => state.imageSize);
	const store_annotations = useAppStore((state) => state.annotations);
	const store_updateAnnotations = useAppStore(
		(state) => state.updateAnnotations
	);

	// Activity Data;
	const [state_mode, state_setMode] = useState<"idle" | "drawing">("idle");
	const [state_posStart, state_setPosStart] = useState<Position>({
		x: 0,
		y: 0,
	});
	const uiStageProps = useUIStageProps();
	// Refs
	const state_layerRef = useRef<Konva.Layer>(null);
	const state_baseRectangleRef = useRef<Konva.Rect>(null);
	const state_currentArrowRef = useRef<Konva.Arrow>(null);

	const handleLayerOnMouseDown = (e: Konva.KonvaEventObject<MouseEvent>) => {
		logger.log("handleGraphicsLayerOnMouseDown");
		const position = UICanvasUtil.getMousePosition(e, state_layerRef.current);
		// Reset current rectangle
		state_currentArrowRef?.current?.points([
			position.x,
			position.y,
			position.x,
			position.y,
		]);

		logger.log("Current Rectangle Ref: ", state_currentArrowRef);
		logger.log("positoin", position);
		state_setPosStart(position);
		state_setMode("drawing");
		// @ts-ignore
	};

	const updateDrag = (posIn: Position) => {
		const points = [state_posStart.x, state_posStart.y, posIn.x, posIn.y];
		state_currentArrowRef?.current?.points(points);

		logger.log("updated rect", points);
	};

	const handleGraphicsLayerOnMouseMove = (
		e: Konva.KonvaEventObject<MouseEvent>
	) => {
		if (state_mode === "drawing") {
			logger.log("moving ", [state_mode]);
			const position = UICanvasUtil.getMousePosition(e, state_layerRef.current);
			updateDrag(position);
		}
	};

	const handleGraphicsLayerOnMouseUp = (
		e: Konva.KonvaEventObject<MouseEvent>
	) => {
		let nextId =
			Object.keys(store_annotations).reduce(
				(maxv, id) => Math.max(maxv, parseInt(id)),
				0
			) + 1;
		let annotation: ArrowAnnotation = {
			annotationId: `${nextId}`,
			type: AnnotationType.arrow,
			// x: state_posStart.x,
			// y: state_posStart.y,
			rotation: 0,
			points: state_currentArrowRef?.current?.points() ?? [0, 0, 0, 0],
		};
		store_updateAnnotations([annotation]);
		state_setMode("idle");
	};

	const initialize = () => {
		logger.log("Initializing Rect");
		// TODO: Modify store to change canvas statefor cursor.
		state_setPosStart({ x: 0, y: 0 });
		uiStageProps.pushNewStoreStageProps({ cursor: "crosshair" });
	};

	useEffect(() => {
		logger.log("AnnotatingArrow mounted");
		initialize();
	}, []);

	const canvasSize = UICanvasUtil.getUICanvasSizeFromImageSize(store_imageSize);

	return (
		<Layer
			ref={state_layerRef}
			onMouseDown={handleLayerOnMouseDown}
			onMouseMove={handleGraphicsLayerOnMouseMove}
			onMouseUp={handleGraphicsLayerOnMouseUp}
		>
			{/* Base Rectangle helps expand the Layer. Because layer 
        starts out having no width or height. 
      */}
			<Rect
				ref={state_baseRectangleRef}
				{...{
					x: 0,
					y: 0,
					width: canvasSize.width,
					height: canvasSize.height,
					fill: "green",
					opacity: 0,
				}}
			/>
			<Arrow
				ref={state_currentArrowRef}
				{...{ points: [0, 0, 0, 0] }}
				{...UIArrowAnnotationDefaultStyleProps}
			/>
		</Layer>
	);
};
