import Konva from "konva";
import React from "react";
import create from "zustand";
import { Annotation, Screenshot } from "../model/models";
import { DEFAULT_UI_STAGE_PROPS } from "../../apps/viewer/canvas/ui_canvas_constants";
import { UICanvasActivityType, UIViewMode } from "../../apps/viewer/models";
import {
	handleUpdateWithScreenshot,
	handleAddAnnotation,
	handleRemoveAnnotation,
	handleUpdateAnnotations,
} from "./action_handlers";
import { AppStore, UIStageProps } from "./app_store.interface";

export const useAppStore = create<AppStore>((set) => ({
	screenshotId: "",
	tabUrl: "",
	originalDataImageString: "",
	dataImageString: "",
	imageSize: { width: 0, height: 0 },
	annotations: {},

	// ui states:
	viewMode: UIViewMode.view,
	activeModal: null,
	// stage
	uiStageProps: DEFAULT_UI_STAGE_PROPS,

	// canvas
	activeUICanvasActivity: UICanvasActivityType.normal,
	uiCanvasAnnotationLayerRef: null,
	uiCanvasStageRef: null,

	// actions: functions
	setViewMode: (viewMode: UIViewMode) => set({ viewMode }),
	setActiveModal: (modal: any) => set({ activeModal: modal }),

	// Screenshot actions
	updateWithScreenshot: (screenshot: Screenshot) =>
		set((state) => handleUpdateWithScreenshot(state, screenshot)),

	// Annotation actions
	addAnnotation: (annotation: Annotation) =>
		set((state) => handleAddAnnotation(state, annotation)),
	removeAnnotation: (annotationId: string) =>
		set((state) => handleRemoveAnnotation(state, annotationId)),
	updateAnnotations: (annotation: Annotation[]) =>
		set((state) => handleUpdateAnnotations(state, annotation)),

	// canvas actions
	setActiveUICanvasActivity: (data: UICanvasActivityType) =>
		set((state) => ({
			activeUICanvasActivity: data,
		})),

	updateUIStageProps: (props: Partial<UIStageProps>) =>
		set((state) => ({
			uiStageProps: { ...state.uiStageProps, ...props },
		})),

	// annotation Layer
	setUICanvasAnnotationLayerRef: (ref: React.RefObject<Konva.Layer>) =>
		set({ uiCanvasAnnotationLayerRef: ref }),

	// stage layer
	setUICanvasStageRef: (ref: React.MutableRefObject<Konva.Stage> | null) =>
		set({ uiCanvasStageRef: ref }),
}));
