import { MEDIA_CONSTANTS } from "../../../lib/constants/constants_media";

const kSize = 1.5; // Canvas size is 1.5 times larger than Max Image size

export const UI_CANVAS_CONTANTS = {
	// CANVAS_SIZE_MULTIPLIER is used to calculate the actual canvas size from
	// the image size.
	// Given the image size, the canvas size would be
	// CANVAS_SIZE_MULTIPLIER * image size.
	// This is also used to calculate the stage width and height.
	//
	CANVAS_SIZE_MULTIPLIER: 1.1,

	Color: {
		background: "#d2d4dc",
	},
};
