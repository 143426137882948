import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FullPageLayout } from "../../lib/components/layout";
import { FullPageLoader } from "../../lib/components/page_loader";
import { useAppStore } from "../../lib/store/app_store";
import { GetLogger } from "../../lib/util/simple_logger";
import { Api } from "./api";
import { NotFoundPage } from "./not_found";
import { ScreenshotViewerContainer } from "./screenshot_viewer";

// const DefaultScreenshot: Screenshot = {
//   id: "",
//   tabUrl: "",
//   dataImageString: "https://cdn.houseplansservices.com/product/du39te6oqh62mvb1bhgm1cs91h/w800x533.jpg?v=23"
// }
const logger = GetLogger("screenshot_viewer_page");

export const ScreenshotViewerPage = () => {
	const [isLoading, setIsLoading] = useState(true);
	const dataImageString = useAppStore((state) => state.dataImageString);
	const updateWithScreenshot = useAppStore(
		(state) => state.updateWithScreenshot
	);

	const params = useParams();

	const initializeScreenshot = useCallback(async () => {
		const screenshotId = params.screenshotId ?? "";
		const ds = await Api.getScreenshot(screenshotId);
		if (ds) {
			logger.log("received screenshot", ds);
			updateWithScreenshot(ds);
		}
		setIsLoading(false);
	}, [params.screenshotId, setIsLoading, updateWithScreenshot]);

	useEffect(() => {
		// attempt to fetch screenshot
		initializeScreenshot();
	}, [initializeScreenshot]);

	if (isLoading) {
		return <FullPageLoader />;
	}

	if (dataImageString.length <= 0) {
		return <NotFoundPage />;
	}

	return (
		<FullPageLayout>
			<ScreenshotViewerContainer />
		</FullPageLayout>
	);
};
