import { Img } from "@chakra-ui/react";
import SvgArrowSrc from "../assets/annotation_arrow_icon.svg";
import SvgRectangleSrc from "../assets/annotation_rectangle_icon.svg";
import SvgTextSrc from "../assets/annotation_text_icon.svg";

export const AnnotationRectangleIcon = (props: any) => {
	return <Img src={SvgRectangleSrc} width="20px" {...props} />;
};
export const AnnotationArrowIcon = (props: any) => {
	return <Img src={SvgArrowSrc} width="20px" {...props} />;
};
export const AnnotationTextIcon = (props: any) => {
	return <Img src={SvgTextSrc} width="20px" {...props} />;
};
