import { Box, Text, VStack } from "@chakra-ui/react";
import { FullPageLayout } from "../../lib/components/layout";
import { Logo } from "../../lib/components/logo";

const PRIMARY_COLOR = "#6b62ff";

// const CTAChromeButton = () => {
// 	return (
// 		<a href="#" target="_blank">
// 			<HStack
// 				rounded="md"
// 				color="white"
// 				padding="8px"
// 				fontWeight={"semibold"}
// 				borderWidth="1px"
// 				borderColor="#3f5efb"
// 			>
// 				<Text>Get the chrome extension</Text>
// 				<ExternalLinkIcon color="white" />
// 			</HStack>
// 		</a>
// 	);
// };

const CTAComingSoon = () => {
	return (
		<a href="#">
			<VStack
				rounded="md"
				color="white"
				padding="8px"
				fontWeight={"semibold"}
				borderWidth="1px"
				borderColor="#3f5efb"
				width="100%"
				textAlign={"center"}
			>
				<Text align="center">
					Chrome extension coming soon...
					<br />
				</Text>
				<Text>
					we are currently getting feedback to improve screensnyp.
					<span role="img" aria-label="sheep">
						🤞
					</span>
				</Text>
				{/* <ExternalLinkIcon color="white" /> */}
			</VStack>
		</a>
	);
};

export const HomePage = () => {
	return (
		<FullPageLayout>
			<VStack
				width="100%"
				height="100%" // ""
				background="linear-gradient(to right, #512da8, #711e72)"
				// background="linear-gradient(to right, #512da8, #6b62ff)"
				// background="linear-gradient(to right, #6b62ff, #711e72)"
				paddingTop={["30%", "20%"]}
				spacing="25px"
				position="relative"
			>
				{/* <Box width="100%" height="40px"> */}
				<VStack spacing="2px">
					<Logo width="80px" height="80px" />
					<Text color="white" fontWeight={"bold"}>
						screensnyp
					</Text>
				</VStack>
				<Text align="center" color="white" fontSize="2xl">
					Take the best browser screenshots, annotate and share them!
				</Text>
				{/* <Button
					aria-label={"get chrome extension"} // icon={<OpenN}
					// rightIcon={<OpenNewIcon />}
					rightIcon={<ExternalLinkIcon color="white" />}
					color="white"
					background={"inherit"}
					borderWidth="1px"
					borderColor="#3f5efb"
					_hover={{}}
				>
					Get the chrome extension
				</Button> */}
				<Box>
					<CTAComingSoon />
				</Box>
				{/* </Box> */}
			</VStack>
			{/* <Box
				position="absolute"
				bottom="0"
				left="0"
				width="100%"
				textAlign={"center"}
				color="white"
				fontSize="10px"
				padding="4px"
			>
				<Text> copyright (c) chronleap</Text>
			</Box> */}
		</FullPageLayout>
	);
};
