export const x = 3;

export enum UIViewMode {
	view = "view",
	edit = "edit",
}

// export type UICanvasActivityType =
// 	| "normal"
// 	| "annotating-rectangle"
// 	| "annotating-arrow"
// 	| "annotating-text";

export enum UICanvasActivityType {
	normal = "normal",
	annotatingRectangle = "annotating-rectangle",
	annotatingArrow = "annotating-arrow",
	annotatingText = "annotating-text",
}

export enum UICanvasActivityTypeGroup {
	normal = "normal",
	annotating = "annotating",
}
// type UICanvasActivityTypeGroup = "normal" | "annotating";

/**
 * CONSTANTS
 */

export const UICANVAS_ACTIVITY_TYPE_GROUP_MAP: Record<
	UICanvasActivityTypeGroup,
	UICanvasActivityType[]
> = {
	normal: [UICanvasActivityType.normal],
	annotating: [
		UICanvasActivityType.annotatingRectangle,
		UICanvasActivityType.annotatingArrow,
	],
};
