import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import AppPlayground from "./apps/playground/app_playground";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
// some comment
root.render(
	<React.StrictMode>
		{process.env.REACT_APP_APP_ENTRY === "playground" ? (
			<AppPlayground />
		) : (
			<App />
		)}
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
