import { useCallback, useEffect, useState } from "react";
import { Screenshot } from "../../../lib/model/models";
import { useAppStore } from "../../../lib/store/app_store";
import { GetLogger } from "../../../lib/util/simple_logger";
import { Api } from "../api";
import { UIViewMode } from "../models";

const logger = GetLogger("auto_save_edit");

export const AutoSaveEditContainer = () => {
	const store_viewMode = useAppStore((state) => state.viewMode);
	const store_screenshotId = useAppStore((state) => state.screenshotId);
	const store_tabUrl = useAppStore((state) => state.tabUrl);
	const store_dataImageString = useAppStore((state) => state.dataImageString);
	const store_imageSize = useAppStore((state) => state.imageSize);
	const store_annotations = useAppStore((state) => state.annotations);

	const [prevViewMode, setPrevViewMode] = useState<UIViewMode | null>(null);

	const updateBackend = useCallback(async () => {
		const screenshot: Screenshot = {
			screenshotId: store_screenshotId,
			tabUrl: store_tabUrl,
			dataImageString: store_dataImageString,
			imageWidth: store_imageSize.width,
			imageHeight: store_imageSize.height,
			annotations: Object.values(store_annotations),
		};
		logger.log("for backend", {
			screenshotId: screenshot.screenshotId,
			annotations: screenshot.annotations,
		});
		await Api.updateScreenshot(screenshot);
	}, [
		store_screenshotId,
		store_tabUrl,
		store_dataImageString,
		store_imageSize,
		store_annotations,
	]);

	useEffect(() => {
		if (
			prevViewMode !== null &&
			prevViewMode !== store_viewMode &&
			store_viewMode === UIViewMode.view
		) {
			updateBackend();
			logger.log("updating backend");
		}
		setPrevViewMode(store_viewMode);
	}, [store_viewMode, prevViewMode, setPrevViewMode, updateBackend]);

	return <div></div>;
};
