import { Center, Spinner, VStack } from "@chakra-ui/react";
import { FullPageLayout } from "./layout";

export const FullPageLoader = () => {
	return (
		<FullPageLayout>
			<Center
				width="100%"
				height="100%"
				// background={"rgba(0, 0, 0, 0.8)"} //
				background="white"
			>
				<VStack>
					<Spinner
						thickness="10px"
						speed="0.65s"
						emptyColor="gray.200"
						color="blue.500"
						size="xl"
					></Spinner>
				</VStack>
			</Center>
		</FullPageLayout>
	);
};
