import { Box } from "@chakra-ui/react";
import React from "react";
import { Size } from "../../lib/model/models";
import { useAppStore } from "../../lib/store/app_store";
import { AnnotationList } from "../../lib/store/app_store.interface";
import { UICanvas } from "./canvas/ui_canvas";
import { UI_CANVAS_CONTANTS } from "./canvas/ui_canvas_contants";
import { AutoSaveEditContainer } from "./common/auto_save_edit_container";
import { UIViewMode } from "./models";
import { ToolbarContainer } from "./toolbar/toolbar";

interface ScreenshotViewerProps {
	mode: UIViewMode;
	dataImageString: string;
	imageSize: Size;
	annotations: AnnotationList;
}
const ScreenshotViewer: React.FC<ScreenshotViewerProps> = (props) => {
	return (
		<React.Fragment>
			<Box
				// position={"fixed"}
				// background="blue"
				// top={0}
				// left={0}
				width="100%"
				height={"100%"}
				// paddingTop="40px"
				// cursor="crosshair"
				// background={APPEARANCE.background}
				// background="rgb(195, 203, 217, 0.3)"
			>
				<Box
					width="100%"
					height="100%"
					// spacing="20px"
					// background="green"
					position="absolute"
					padding="10px"
				>
					<Box
						width="100%"
						// maxWidth={`${Math.max(imageWidth, 400)}px`}
						position="absolute"
						//
						top="10px"
						// left="50px"
					>
						<ToolbarContainer />
					</Box>
					<Box
						// maxWidth="800px"
						height="100%"
						position="absolute"
						// background="red"
						top="55px"
						width="100%"
						// left="50px"
						background={UI_CANVAS_CONTANTS.Color.background}
					>
						<UICanvas />
					</Box>
				</Box>
			</Box>
			<AutoSaveEditContainer />
		</React.Fragment>
	);
};

export const ScreenshotViewerContainer: React.FC = () => {
	const dataImageString = useAppStore((state) => state.dataImageString);
	const imageSize = useAppStore((state) => state.imageSize);
	const viewMode = useAppStore((state) => state.viewMode);
	const annotations = useAppStore((state) => state.annotations);

	return (
		<ScreenshotViewer
			mode={viewMode}
			dataImageString={dataImageString}
			imageSize={imageSize}
			annotations={annotations}
		/>
	);
};
