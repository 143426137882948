import Konva from "konva";
import { useCallback, useEffect, useRef } from "react";
import { Layer, Rect } from "react-konva";
import {
	AnnotationType,
	ArrowAnnotation,
	RectangleAnnotation,
	TextAnnotation,
} from "../../../lib/model/models";
import { useAppStore } from "../../../lib/store/app_store";
import { GetLogger } from "../../../lib/util/simple_logger";
import { UICanvasActivityType, UIViewMode } from "../models";
import { UIArrowAnnotation } from "./annotation/ui_arrow_annotation";
import { UIRectangleAnnotation } from "./annotation/ui_rectangle_annotation";
import { UITextAnnotation } from "./annotation/ui_text_annotation";
import { UICanvasUtil } from "./ui_canvas_util";

const logger = GetLogger("ui_canvas_annotation_layer");

interface CanvasAnnotationsLayerProps {
	// annotations: AnnotationList;
}

/**
 * CanvasAnnotationLayer
 * This layer is used to display objects
 * @param props
 * @returns
 */
export const UICanvasAnnotationLayer: React.FC<CanvasAnnotationsLayerProps> = (
	props
) => {
	const state_activeActivity = useAppStore(
		(state) => state.activeUICanvasActivity
	);
	const state_annotations = useAppStore((state) => state.annotations);
	const state_imageSize = useAppStore((state) => state.imageSize);
	const state_viewMode = useAppStore((state) => state.viewMode);
	const store_setUICanvasAnnotationLayerRef = useAppStore(
		(state) => state.setUICanvasAnnotationLayerRef
	);
	const store_setActivity = useAppStore(
		(state) => state.setActiveUICanvasActivity
	);
	const handleOnClick = (e: Konva.KonvaEventObject<MouseEvent>) => {
		logger.log("clicked");
	};

	const state_layerRef = useRef<Konva.Layer>(null);

	const handleOnKeyboardEvent = useCallback(
		(e: KeyboardEvent) => {
			logger.log("key pressed", e.key);
			if (
				e.key === "Escape" &&
				state_activeActivity !== UICanvasActivityType.normal
			) {
				// transition to normal activity.
				store_setActivity(UICanvasActivityType.normal);
			}
		},
		[state_activeActivity, store_setActivity]
	);

	useEffect(() => {
		if (state_layerRef.current) {
			logger.log("layer ref changed", state_layerRef.current);
			store_setUICanvasAnnotationLayerRef(state_layerRef);

			window.addEventListener("keydown", handleOnKeyboardEvent);
			return () => {
				window.removeEventListener("keydown", handleOnKeyboardEvent);
			};
		}
	}, [
		state_layerRef,
		store_setUICanvasAnnotationLayerRef,
		handleOnKeyboardEvent,
	]);

	const canvasSize = UICanvasUtil.getUICanvasSizeFromImageSize(state_imageSize);

	return (
		<Layer ref={state_layerRef}>
			{/* add annotation to this layer */}
			<Rect
				x={0}
				y={0}
				width={canvasSize.width}
				height={canvasSize.height}
				fill="red"
				opacity={0}
				stroke={"red"}
				dash={[5, 10]}
				onClick={handleOnClick}
				onMouseEnter={handleOnClick}
			/>
			{Object.values(state_annotations).map((annot) => {
				switch (annot.type) {
					case AnnotationType.rectangle:
						return (
							<UIRectangleAnnotation
								key={annot.annotationId}
								annotation={annot as RectangleAnnotation}
							/>
						);
					case AnnotationType.arrow:
						return (
							<UIArrowAnnotation
								key={annot.annotationId}
								annotation={annot as ArrowAnnotation}
							/>
						);
					case AnnotationType.text:
						return (
							<UITextAnnotation
								key={annot.annotationId}
								annotation={annot as TextAnnotation}
							/>
						);
					default:
						return null;
				}
			})}
			{/* A Disabling Rectangle */}
			{state_viewMode === UIViewMode.view && (
				<Rect
					x={0}
					y={0}
					width={canvasSize.width}
					height={canvasSize.height}
					fill="red"
					opacity={0}
				/>
			)}
		</Layer>
	);
};
