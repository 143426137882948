export interface BackgroundLoadingModalPayload {
	callback: () => Promise<void>;
}

interface NoPayload {}
export interface ModalProps {
	id: string;
	payload: BackgroundLoadingModalPayload | NoPayload;
}

export const ModalIds = {
	BackgroundLoading: "BackgroundLoading",
};
