import { Box, Img } from "@chakra-ui/react";
import LogoImg from "../assets/logo.png";

export const Logo = (props: any) => {
	return (
		<a href="/">
			<Box {...props}>
				<Img src={LogoImg} />;
			</Box>
		</a>
	);
};
