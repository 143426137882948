import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CommonModal } from "../../lib/components/modals/common_modal";
import { HomePage } from "./home";
import { ScreenshotViewerPage } from "./screenshot_viewer_page";

export const AppViewer = () => {
	return (
		<ChakraProvider>
			<React.Fragment>
				<BrowserRouter>
					<Routes>
						<Route path="/:screenshotId" element={<ScreenshotViewerPage />} />
						<Route path="/" element={<HomePage />} />
					</Routes>
				</BrowserRouter>
				<CommonModal />
			</React.Fragment>
		</ChakraProvider>
	);
};
