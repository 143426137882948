const parseString = (value: string | undefined): string => {
	if (value === undefined) {
		return "";
	}
	return value;
	// const all: string[] = [];
	// for (let i = 0; i < value.length; i++) {
	// 	if (value[i] !== '"' && value[i] !== "'") {
	// 		all.push(value[i]);
	// 	}
	// }
	// return all.join("");
};

export const Config = {
	APP_ENTRY: process.env.REACT_APP_APP_ENTRY ?? "",
	APP_LOG_TYPE: process.env.REACT_APP_ENABLE_LOG ?? "all", // all | none
	WEB_APP_DOMAIN: parseString(process.env.REACT_APP_WEB_APP_DOMAIN),
	API_BASE_URL: parseString(process.env.REACT_APP_API_BASE_URL),
};
