import { DownloadIcon, EditIcon } from "@chakra-ui/icons";
import { Box, Button, HStack, IconButton, Text } from "@chakra-ui/react";
import { Logo } from "../../../lib/components/logo";
import { MEDIA_CONSTANTS } from "../../../lib/constants/constants_media";
import { MediaUtil } from "../../../lib/media/media_util";
import { useAppStore } from "../../../lib/store/app_store";
import { UICanvasActivityType, UIViewMode } from "../models";
import { AnnotationToolbarContainer } from "./annotation_toolbar";

const truncateText = (text: string, maxlen: number = 100): string => {
	if (text.length > maxlen) {
		return text.substring(0, maxlen) + "...";
	}
	return text;
};
interface ToolbarProps {
	tabUrl: string;
	mode: UIViewMode;
	onEdit: () => void;
	onCompleteEdit: () => void;
	onDownload: () => void;
}
const Toolbar: React.FC<ToolbarProps> = (props) => {
	return (
		<HStack
			width="100%"
			height="40px"
			//
			// background="blue.200"
			spacing="40px"
		>
			<Logo width="30px" height="30px" />
			<a href={props.tabUrl} target="_blank" rel="noreferrer">
				<Box
					rounded="md"
					padding="5px"
					height="100%"
					borderWidth="1px"
					borderColor="inherit" // "{APPEARANCE.primary1}
					maxWidth="400px"
					// color={APPEARANCE.foreground}
				>
					<Text> Url: {truncateText(props.tabUrl, 40)} </Text>
				</Box>
			</a>
			{/* Download Button.  */}
			<HStack spacing="10px">
				<IconButton
					icon={<DownloadIcon />}
					aria-label={"download"}
					onClick={props.onDownload}
				/>
				{/* <Spacer /> */}

				{props.mode === "view" ? (
					<Button onClick={props.onEdit} leftIcon={<EditIcon />}>
						Edit
					</Button>
				) : (
					<Button onClick={props.onCompleteEdit}> Done</Button>
				)}
			</HStack>

			{props.mode === "edit" && <AnnotationToolbarContainer />}
		</HStack>
	);
};

export const ToolbarContainer: React.FC = () => {
	const store_tabUrl = useAppStore((state) => state.tabUrl);
	const store_viewMode = useAppStore((state) => state.viewMode);
	const state_setViewMode = useAppStore((state) => state.setViewMode);
	const state_setCanvasActivity = useAppStore(
		(state) => state.setActiveUICanvasActivity
	);
	const store_uiCanvasStageRef = useAppStore((state) => state.uiCanvasStageRef);

	// const handler = useCanvasActivityTransitionManager();

	const handleOnEdit = () => {
		state_setViewMode(UIViewMode.edit);
		state_setCanvasActivity(UICanvasActivityType.normal);
	};
	const handleOnCompleteEdit = () => {
		state_setViewMode(UIViewMode.view);
		state_setCanvasActivity(UICanvasActivityType.normal);
		// save changes to backend.
		// const screenshot = prepareScreenshot();
		// Api.updateScreenshot(screenshot);
	};

	const handleOnDownload = async () => {
		if (!store_uiCanvasStageRef || !store_uiCanvasStageRef.current) {
			// return;
			return;
		}
		const dataUrl = store_uiCanvasStageRef?.current.toDataURL({
			pixelRatio: 1,
			mimeType: MEDIA_CONSTANTS.MIME_TYPE,
		});
		MediaUtil.downloadImageUrl(dataUrl, "screenshot.png");
	};

	return (
		<Toolbar
			tabUrl={store_tabUrl}
			mode={store_viewMode}
			onEdit={handleOnEdit}
			onCompleteEdit={handleOnCompleteEdit}
			onDownload={handleOnDownload}
		/>
	);
};
