import Konva from "konva";
import React, { useCallback } from "react";
import { useEffect } from "react";
import { GetLogger } from "../../../../lib/util/simple_logger";
import { useAppStore } from "../../../../lib/store/app_store";
import { UIStageProps } from "../../../../lib/store/app_store.interface";

const logger = GetLogger("ui_canvas_activity_manager");

interface RegisterAnnotationLayerListenerProps {
	handleOnClickOutside?: (e: Konva.KonvaEventObject<MouseEvent>) => void;
	handleOnKeyDown?: (e: KeyboardEvent) => void;
}

export const useRegisterAnnotationLayerListener = (
	props: RegisterAnnotationLayerListenerProps
) => {
	const store_uiCanvasAnnotationLayerRef = useAppStore(
		(state) => state.uiCanvasAnnotationLayerRef
	);

	useEffect(() => {
		// if (state_isSelected) {
		logger.log("Registering Click");
		if (props.handleOnClickOutside) {
			store_uiCanvasAnnotationLayerRef?.current?.on(
				"click",
				props.handleOnClickOutside
			);
		}
		if (props.handleOnKeyDown) {
			window.addEventListener("keydown", props.handleOnKeyDown);
		}
		const ref = store_uiCanvasAnnotationLayerRef?.current;
		return () => {
			logger.log("removing ...");
			if (ref && props.handleOnClickOutside) {
				ref?.off("click", props.handleOnClickOutside);
			}
			if (props.handleOnKeyDown) {
				window.removeEventListener("keydown", props.handleOnKeyDown);
			}
		};
	}, [
		store_uiCanvasAnnotationLayerRef,
		props.handleOnClickOutside,
		props.handleOnKeyDown,
	]);
};

export const useUIStageProps = () => {
	const store_uiStageProps = useAppStore((state) => state.uiStageProps);
	const store_updateUIStageProps = useAppStore(
		(state) => state.updateUIStageProps
	);
	const [state_prevUIStageProps, state_setPrevUIStageProps] = React.useState<
		UIStageProps[]
	>([]);

	const pushNewStoreStageProps = useCallback(
		(pp: UIStageProps) => {
			if (store_uiStageProps) {
				state_setPrevUIStageProps([
					...state_prevUIStageProps,
					store_uiStageProps,
				]);
			}
			// state_setPrevUIStageProps([...state_prevUIStageProps, pp]);
			store_updateUIStageProps(pp);
		},
		[store_uiStageProps, state_prevUIStageProps, store_updateUIStageProps]
	);

	const popStoreStageProps = useCallback(() => {
		if (state_prevUIStageProps.length > 0) {
			const prevpp = [...state_prevUIStageProps];
			const pp = prevpp.pop();
			if (pp) {
				store_updateUIStageProps(pp);
			}
			state_setPrevUIStageProps(prevpp);
		}
	}, [
		state_prevUIStageProps,
		store_updateUIStageProps,
		state_setPrevUIStageProps,
	]);

	return {
		uiStageProps: store_uiStageProps,
		pushNewStoreStageProps,
		popStoreStageProps,
	};
};
