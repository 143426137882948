import "./App.css";
import { AppScreenshotContainer } from "./apps/chrome/app.index";
import AppPlayground from "./apps/playground/app_playground";
import { AppViewer } from "./apps/viewer/app.index";
// import { AppScreenshot } from "./apps/chrome/app_screenshot";

function App() {
	if (process.env.REACT_APP_APP_ENTRY === "chrome") {
		return <AppScreenshotContainer />;
	} else if (process.env.REACT_APP_APP_ENTRY === "web") {
		return <AppViewer />;
	} else if (process.env.REACT_APP_APP_ENTRY === "playground") {
		return <AppPlayground />;
	}
	return <div></div>;
}

export default App;
