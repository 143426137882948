export enum AnnotationType {
	rectangle = "rectangle",
	arrow = "arrow",
	text = "text",
}

export interface Annotation {
	annotationId: string;
	type: AnnotationType;
	[key: string]: any;
}

export interface RectangleAnnotation extends Annotation {
	type: AnnotationType.rectangle;
	x: number; // x coordinate of top left corner of rectangle
	y: number; // y coordinate of top left corner of rectangle
	width: number;
	height: number;
	rotation: number;
}
export interface ArrowAnnotation extends Annotation {
	type: AnnotationType.arrow;
	// x: number;
	// y: number;
	rotation: number;
	points: number[];
}
export interface TextAnnotation extends Annotation {
	type: AnnotationType.text;
	text: string;
	x: number;
	y: number;
	rotation: number;
	width?: number;
	height?: number;
	// fontSize: 20,
}

export interface Screenshot {
	screenshotId?: string;
	tabUrl?: string; // url of the tab from which screenshot was taken.
	dataImageString?: string; // base64 encoded image
	imageWidth?: number; // width of image in px.
	imageHeight?: number; // height of image in px.
	// metadata?: any;
	annotations?: Annotation[];
}

export interface Position {
	x: number;
	y: number;
}
export interface Scale {
	x: number;
	y: number;
}
export interface Size {
	width: number;
	height: number;
}
