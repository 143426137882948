import { Center, Spinner, VStack } from "@chakra-ui/react";
import { useEffect } from "react";
import { useAppStore } from "../../store/app_store";
import { GetLogger } from "../../util/simple_logger";
import { FullPageLayout } from "../layout";
import {
	BackgroundLoadingModalPayload,
	ModalProps,
} from "./common_modal.interface";

const logger = GetLogger("background_loading_modal");

export const BackgroundLoadingModalContainer: React.FC<ModalProps> = (
	props
) => {
	// const { setActiveModal } = useStatBookContext();
	const setActiveModal = useAppStore((store) => store.setActiveModal);

	// const { isOpen, onClose } = useDisclosure();
	const { callback } = props.payload as BackgroundLoadingModalPayload;
	useEffect(() => {
		// invoke callback and wait for it.
		logger.log("Invoking Callback", callback);
		callback().then(() => {
			// Dismiss modal.
			setActiveModal(null);
		});
	}, []);

	return (
		<FullPageLayout>
			<Center
				width="100%"
				height="100%"
				background={"rgba(10, 13, 20, 0.74)"} //
				// background="white"
			>
				<VStack>
					<Spinner
						thickness="10px"
						speed="0.65s"
						emptyColor="gray.200"
						color="blue.500"
						size="xl"
					></Spinner>
				</VStack>
			</Center>
		</FullPageLayout>
	);
};
