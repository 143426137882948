import { useAppStore } from "../../store/app_store";
import { BackgroundLoadingModalContainer } from "./background_loading_modal";
import { ModalIds } from "./common_modal.interface";

interface CommonBoundaryProps {
	children?: any;
}

export const CommonModal: React.FC<CommonBoundaryProps> = (props) => {
	// const { activeModal } = useStatBookContext();
	const activeModal = useAppStore((store) => store.activeModal);

	if (!activeModal) {
		return <div></div>;
	}

	if (activeModal.id === ModalIds.BackgroundLoading) {
		return <BackgroundLoadingModalContainer {...activeModal} />;
	}

	return <div></div>;
};
