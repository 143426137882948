const downloadImageUrl = (url: string, name: string) => {
	const a = document.createElement("a");
	a.href = url;
	a.download = name;
	a.click();
	// todo, remove the element after download.

	// a.remove();
};

export const MediaUtil = {
	downloadImageUrl,
};
