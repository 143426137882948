import { Image as KonvaImage, Layer, Rect } from "react-konva";
import useImage from "use-image";
import { useAppStore } from "../../../lib/store/app_store";
import { UICanvasUtil } from "./ui_canvas_util";

/**
 * UICanvasStaticLayer
 * This is a static layer. Contains things that aren't redrawn on every frame.
 * @returns
 */
export const UICanvasStaticLayer = () => {
	const dataImageString = useAppStore((state) => state.dataImageString);
	const [image] = useImage(dataImageString ?? "", "anonymous");
	const store_imageSize = useAppStore((state) => state.imageSize);
	const canvasSize = UICanvasUtil.getUICanvasSizeFromImageSize(store_imageSize);

	// activity handlers. These are used to handle user interaction with the canvas.
	// const activity = useAppStore((state) => state.activeUICanvasActivityData);
	// const handler = useUICanvasActivityHandler(activity);

	return (
		<Layer
			scale={{ x: 1, y: 1 }}
			//
		>
			{/* This serves as the background of the canvas.  */}
			<Rect
				{...{
					x: 0,
					y: 0,
					width: canvasSize.width,
					height: canvasSize.height,
					fill: "#d2d4dc",
					opacity: 1,
				}}
			/>
			<KonvaImage
				// ref={imageRef}
				image={image}
				listening={false}
				x={0}
				y={0}
				width={store_imageSize.width * 1}
				height={store_imageSize.height * 1}
			/>
		</Layer>
	);
};
