import React, { useEffect, useState } from "react";
import { Stage } from "react-konva";
import { useAppStore } from "../../../lib/store/app_store";
import { GetLogger } from "../../../lib/util/simple_logger";
import { UICanvasActivityType } from "../models";
import { UICanvasActivityLayer } from "./ui_canvas_activity_layer";
import { UICanvasAnnotationLayer } from "./ui_canvas_annotation_layer";
import { DEFAULT_UI_STAGE_PROPS } from "./ui_canvas_constants";
import { UICanvasStaticLayer } from "./ui_canvas_static_layer";
import { UICanvasUtil } from "./ui_canvas_util";

const logger = GetLogger("ui_canvas");
logger.log("ui_canvas");

export const UICanvas = () => {
	const activity = useAppStore((state) => state.activeUICanvasActivity);
	const store_imageSize = useAppStore((state) => state.imageSize);
	const store_stageProps = useAppStore((state) => state.uiStageProps);
	const store_updateUIStageProps = useAppStore(
		(state) => state.updateUIStageProps
	);

	const store_setUICanvasStageRef = useAppStore(
		(state) => state.setUICanvasStageRef
	);
	const store_uiCanvasStageRef = useAppStore((store) => store.uiCanvasStageRef);

	const state_stageRef = React.useRef<any>(null);
	const [state_prevActivity, state_setPrevActivity] =
		useState<UICanvasActivityType | null>(null);

	useEffect(() => {
		// logger.log("comparing", [state_prevActivity, activity]);
		if (
			state_prevActivity !== activity &&
			activity === UICanvasActivityType.normal
		) {
			store_updateUIStageProps(DEFAULT_UI_STAGE_PROPS);
		}
		state_setPrevActivity(activity);
	}, [
		activity,
		state_prevActivity,
		store_updateUIStageProps,
		state_setPrevActivity,
	]);

	useEffect(() => {
		if (state_stageRef !== store_uiCanvasStageRef) {
			logger.log("setting stage ref", [state_stageRef, store_uiCanvasStageRef]);
			store_setUICanvasStageRef(state_stageRef);
		}
	}, [state_stageRef, store_setUICanvasStageRef, store_uiCanvasStageRef]);

	const canvasSize = UICanvasUtil.getUICanvasSizeFromImageSize(store_imageSize);

	return (
		<Stage
			ref={state_stageRef}
			width={canvasSize.width}
			height={canvasSize.height}
			// ref={stageRef}
			//
			scaleX={1}
			scaleY={1}
			style={{
				cursor: store_stageProps?.cursor,
			}}
			editable={false}
		>
			{/* Layer Ordering matters here */}
			<UICanvasStaticLayer />
			<UICanvasAnnotationLayer />
			<UICanvasActivityLayer />
		</Stage>
	);
};
