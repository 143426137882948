import React from "react";
import { useAppStore } from "../../../lib/store/app_store";
import { GetLogger } from "../../../lib/util/simple_logger";
import { UICanvasActivityType } from "../models";
import { UIAnnotatingArrowActivityLayer } from "./activity/ui_annotating_arrow_activity_layer";
import { UIAnnotatingRectangleActivityLayer } from "./activity/ui_annotating_rectangle_activity_layer";
import { UIAnnotatingTextActivityLayer } from "./activity/ui_annotating_text_activity_layer";

const logger = GetLogger("canvas_graphics_layer");
logger.log("canvas_graphics_layer");

interface UICanvasActivityLayerProps {
	// annotations: AnnotationList;
}

/**
 * CanvasAnnotationLayer
 * This layer is used to display objects
 * @param props
 * @returns
 */
export const UICanvasActivityLayer: React.FC<UICanvasActivityLayerProps> = (
	props
) => {
	const activity = useAppStore((state) => state.activeUICanvasActivity);

	return (
		// {Subscribe }
		// Note: Layers take 0 size util something is put in it.
		// it tries to bound just the content of it's children on the stage.
		<React.Fragment>
			{activity === UICanvasActivityType.annotatingRectangle && (
				<UIAnnotatingRectangleActivityLayer />
			)}
			{activity === UICanvasActivityType.annotatingArrow && (
				<UIAnnotatingArrowActivityLayer />
			)}
			{activity === UICanvasActivityType.annotatingText && (
				<UIAnnotatingTextActivityLayer />
			)}
		</React.Fragment>
	);
};
