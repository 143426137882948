import { Annotation, Screenshot } from "../model/models";
import { AppStore } from "./app_store.interface";

interface ActionHandler {
	(state: AppStore, ...args: any[]): Partial<AppStore>;
}

type AnnotationList = Record<string, Annotation>;

const toAnnotationList = (annotations: Annotation[]): AnnotationList => {
	let annotationList: AnnotationList = {};
	annotations.forEach((annotation) => {
		annotationList[annotation.annotationId] = annotation;
	});
	return annotationList;
};

export const handleUpdateWithScreenshot: ActionHandler = (
	state: AppStore,
	screenshot: Screenshot
) => {
	return {
		screenshotId: screenshot.screenshotId,
		dataImageString: screenshot.dataImageString,
		// originalDataImageString: screenshot.dataImageString,
		tabUrl: screenshot.tabUrl,
		imageSize: {
			width: screenshot.imageWidth ?? 0,
			height: screenshot.imageHeight ?? 0,
		},
		annotations: toAnnotationList(screenshot.annotations ?? []),
	};
};

export const handleAddAnnotation = (
	state: AppStore,
	annotation: Annotation
): Partial<AppStore> => {
	return {
		annotations: {
			...state.annotations,
			[annotation.annotationId]: annotation,
		},
	};
};

export const handleRemoveAnnotation = (
	state: AppStore,
	annotationId: string
): Partial<AppStore> => {
	let annotations = state.annotations;
	const newAnnotations: AnnotationList = {};
	Object.keys(annotations)
		.filter((k) => k !== annotationId)
		.forEach((id) => {
			newAnnotations[id] = annotations[id];
		});

	return {
		annotations: newAnnotations,
	};
};

/**
 *
 * @param state
 * @param annotations
 * @returns
 */
export const handleUpdateAnnotations = (
	state: AppStore,
	annotations: Annotation[]
): Partial<AppStore> => {
	const newAnnotations = { ...state.annotations };
	annotations.forEach((el) => {
		const part = newAnnotations[el.annotationId];
		if (part) {
			newAnnotations[el.annotationId] = { ...part, ...el };
		} else {
			newAnnotations[el.annotationId] = el;
		}
	});

	return {
		annotations: newAnnotations,
	};
};
