import { Box, ChakraProvider } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FullPageLayout } from "../../lib/components/layout";
import { Config } from "../../lib/config/config";
import { Screenshot } from "../../lib/model/models";
import { useAppStore } from "../../lib/store/app_store";
import { GetLogger } from "../../lib/util/simple_logger";
import { AppScreenshot } from "../chrome/app.index";
import { ScreenshotViewerContainer } from "../viewer/screenshot_viewer";
import { imgdata } from "./data";
// import { AppScreenshot } from "./apps/chrome/app_screenshot";
const logger = GetLogger("app_playground");
// const src1 =
// 	"https://cdn.houseplansservices.com/product/du39te6oqh62mvb1bhgm1cs91h/w800x533.jpg?v=23";

function ChromeApp() {
	const shot: Screenshot = {
		screenshotId: "1000",
		dataImageString: imgdata,
		imageHeight: 500,
		imageWidth: 800,
		tabUrl: "https://www.google.com",
		annotations: [],
	};

	// const handleOnCrop = async (cropResult: CropResult) => {
	// 	// TODO: send crop result to backend
	// 	logger.log("Crop Result", cropResult);
	// 	const shot2: Screenshot = {
	// 		...shot,
	// 		dataImageString: cropResult.dataImageString,
	// 		imageHeight: cropResult.height,
	// 		imageWidth: cropResult.width,
	// 	};

	// 	const response = await api.Api.putScreenshot(shot2);
	// 	// window.location.href = `${Config.WEB_APP_DOMAIN}/${shot2.screenshotId}`;
	// 	logger.log("Response rceived ", response);
	// };

	const handleOnNavigateToViewer = (screenshotId: string) => {
		// setTimeout(() => {
		// 	window.location.href = `${Config.WEB_APP_DOMAIN}/${screenshotId}`;
		// }, 10000);
		window.location.href = `${Config.WEB_APP_DOMAIN}/${screenshotId}`;
	};

	return (
		<AppScreenshot
			screenshot={shot}
			onNavigateToViewer={handleOnNavigateToViewer}
		/>
	);
}

function AppPlayground() {
	const [shot] = useState<Screenshot>({
		screenshotId: "1000",
		dataImageString: imgdata,
		imageHeight: 700,
		imageWidth: 1400,
		tabUrl: "https://www.google.com",
		annotations: [
			// {
			// 	type: "rectangle",
			// 	annotationId: `0`,
			// 	x: 20,
			// 	y: 20,
			// 	width: 50,
			// 	height: 50,
			// } as RectangleAnnotation,
		],
	});

	// const handleOnCrop = async (cropResult: CropResult) => {
	// 	// TODO: send crop result to backend
	// 	logger.log("Crop Result", cropResult);
	// 	const shot2: Screenshot = {
	// 		...shot,
	// 		dataImageString: cropResult.dataImageString,
	// 		imageHeight: cropResult.height,
	// 		imageWidth: cropResult.width,
	// 	};

	// 	const response = await api.Api.putScreenshot(shot2);
	// 	// window.location.href = `${Config.WEB_APP_DOMAIN}/${shot2.screenshotId}`;
	// 	logger.log("Response rceived ", response);
	// };

	const updateWithScreenshot = useAppStore(
		(state) => state.updateWithScreenshot
	);
	const dataImageString = useAppStore((state) => state.dataImageString);

	useEffect(() => {
		updateWithScreenshot(shot);
	}, [updateWithScreenshot, shot]);

	if (dataImageString.length <= 0) {
		return <Box>Nothing to shwo yet.</Box>;
	}

	return (
		<ChakraProvider>
			<FullPageLayout>
				{/* <ScreenshotEditor screenshot={shot} onCrop={handleOnCrop} /> */}
				<ScreenshotViewerContainer />
			</FullPageLayout>
		</ChakraProvider>
	);
}
export default ChromeApp;
export { AppPlayground };
// export default AppPlayground;
