import React from "react";
import { Html } from "react-konva-utils";

function getStyle(width: number, height: number = 0) {
	const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
	const baseStyle = {
		// width: `${40}px`,
		// height:
		// height: `${40}px`,
		width: "100%",
		height: "100%",
		border: "none",
		padding: "0px",
		margin: "0px",
		background: "white",
		outline: "none",
		resize: "none",
		colour: "blue",
		fontSize: "20px",
		fontFamily: "sans-serif",
	};
	if (isFirefox) {
		return baseStyle;
	}
	return {
		...baseStyle,
		margintop: "-4px",
	};
}

interface EditableTextInputProps {
	x: number;
	y: number;
	onToggleEdit: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
	onEditTextChange: (v: string) => void;
	text: string;
	width: number;
	// height: number;
}

export const EditableTextInput = ({
	x,
	y,
	onToggleEdit,
	onEditTextChange,
	text,
	width,
}: // height,
EditableTextInputProps) => {
	function handleEscapeKeys(e: React.KeyboardEvent<HTMLTextAreaElement>) {
		if ((e.key === "Enter" && !e.shiftKey) || e.key === "Escape") {
			onToggleEdit(e);
		}
	}

	function handleTextChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
		onEditTextChange(e.currentTarget.value);
	}

	const style = getStyle(width);
	return (
		<Html
			groupProps={{ x, y, width }}
			divProps={{ style: { opacity: 1, background: "orange", padding: "2px" } }}
		>
			<textarea
				value={text}
				onChange={handleTextChange}
				onKeyDown={handleEscapeKeys}
				// @ts-ignore
				style={{ ...style }}
			/>
		</Html>
	);
};
