import { Box, HStack, IconButton } from "@chakra-ui/react";
import {
	AnnotationArrowIcon,
	AnnotationRectangleIcon,
	AnnotationTextIcon,
} from "../../../lib/components/icons";
// import { useCanvasActivityTransitionManager } from "./canvas/activity/ui_canvas_activity_manager";
import { useAppStore } from "../../../lib/store/app_store";
import { UICanvasActivityType } from "../models";

interface ToggleIconButtonProps {
	ariaLabel: string;
	isActive?: boolean;
	icon: React.ReactElement;
	[propname: string]: any;
}
const ToggleIconButton: React.FC<ToggleIconButtonProps> = ({
	ariaLabel,
	isActive,
	icon,
	...rest
}) => {
	return (
		<IconButton
			aria-label={ariaLabel}
			icon={icon}
			borderWidth="2px"
			borderColor={isActive ? "#6b62ff" : "inherit"}
			{...rest}
		/>
	);
};

const AnnotationToolbar: React.FC = () => {
	const state_activeActivity = useAppStore(
		(state) => state.activeUICanvasActivity
	);
	const state_setActivity = useAppStore(
		(state) => state.setActiveUICanvasActivity
	);

	const handleToggleAnnotatingRectangle = () => {
		state_setActivity(
			state_activeActivity !== UICanvasActivityType.annotatingRectangle
				? UICanvasActivityType.annotatingRectangle
				: UICanvasActivityType.normal
		);
	};
	const handleToggleAnnotatingArrow = () => {
		state_setActivity(
			state_activeActivity !== UICanvasActivityType.annotatingArrow
				? UICanvasActivityType.annotatingArrow
				: UICanvasActivityType.normal
		);
	};
	const handleToggleAnnotatingText = () => {
		state_setActivity(
			state_activeActivity !== UICanvasActivityType.annotatingText
				? UICanvasActivityType.annotatingText
				: UICanvasActivityType.normal
		);
	};

	return (
		<HStack>
			<Box>Annotation Toolbar</Box>
			<ToggleIconButton
				ariaLabel={"rectangle"}
				icon={<AnnotationRectangleIcon />}
				onClick={handleToggleAnnotatingRectangle}
				isActive={
					state_activeActivity === UICanvasActivityType.annotatingRectangle
				}
			/>
			<ToggleIconButton
				ariaLabel={"arrow"}
				icon={<AnnotationArrowIcon />}
				onClick={handleToggleAnnotatingArrow}
				isActive={state_activeActivity === UICanvasActivityType.annotatingArrow}
			/>
			<ToggleIconButton
				onClick={handleToggleAnnotatingText}
				isActive={state_activeActivity === UICanvasActivityType.annotatingText}
				ariaLabel={"text"}
				icon={<AnnotationTextIcon />}
			/>
		</HStack>
	);
};

export const AnnotationToolbarContainer: React.FC = () => {
	return <AnnotationToolbar />;
};
