import { Box } from "@chakra-ui/react";
import { RefObject, useEffect, useRef, useState } from "react";
import { FullPageLayout } from "../../lib/components/layout";
import { Screenshot } from "../../lib/model/models";
import { GetLogger } from "../../lib/util/simple_logger";
import { CropperCanvas, CropResult } from "./cropper_canvas";

const logger = GetLogger("screenshot_editor");
logger.log("screenshot_editor");

// async function getCurrentTab() {
// 	let queryOptions = { active: true, lastFocusedWindow: true };
// 	// `tab` will either be a `tabs.Tab` instance or `undefined`.
// 	let [tab] = await chrome.tabs.query(queryOptions);
// 	return tab;
// }

interface ScreenshotProps {
	screenshot: Screenshot;
	onCrop: (cropResult: CropResult) => void;
}

// const isWithin = (point: number[]) => {
// 	return point[0] >= 0 && point[1] >= 0;
// };

export const ScreenshotEditor: React.FC<ScreenshotProps> = (props) => {
	const [canvasWidth, setCanvasWidth] = useState(0);
	const [canvasHeight, setCanvasHeight] = useState(0);
	const canvasContainerRef = useRef<HTMLDivElement>(null);

	const CheckSize = (ref: RefObject<HTMLDivElement> | null) => {
		if (ref) {
			setCanvasWidth(ref.current?.offsetWidth ?? 300);
			setCanvasHeight(ref.current?.offsetHeight ?? 300);
		}
	};

	// const sq = useSearchParams();

	useEffect(() => {
		CheckSize(canvasContainerRef);
	}, [canvasContainerRef]);

	return (
		<FullPageLayout>
			<Box
				// background="blue"
				width="100%"
				height={"100%"}
				cursor="crosshair"
				tabIndex={0}
			>
				{/* <Box width="100%" height="100%" overflowY="auto"> */}
				<Box
					width="100%"
					height="100%"
					position="absolute"
					top={0}
					left={0}
					// background="orange"
					ref={canvasContainerRef}
				>
					<CropperCanvas
						screenshot={props.screenshot}
						canvasHeight={canvasHeight}
						canvasWidth={canvasWidth}
						onCrop={props.onCrop}
					/>
				</Box>
			</Box>
		</FullPageLayout>
	);
};
