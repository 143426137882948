import axios from "axios";
import { Config } from "../../lib/config/config";
import { Screenshot } from "../../lib/model/models";
import { GetLogger } from "../../lib/util/simple_logger";
const logger = GetLogger("api");
export enum ApiStatus {
	ok = "ok",
	fail = "fail",
}

const getScreenshot = async (
	screenshotId: string
): Promise<Screenshot | null> => {
	interface GetScreenshotResponse {
		screenshot: Screenshot;
		status: "ok" | "error";
	}

	try {
		logger.log("base", Config.API_BASE_URL);
		const response = await axios.get(
			`${Config.API_BASE_URL}/v1/screenshot/${screenshotId}`
		);
		const data: GetScreenshotResponse = response.data;
		if (data.status === "ok") {
			return data.screenshot;
		}
	} catch (e) {}
	return null;
};

const updateScreenshot = async (
	screenshot: Screenshot
): Promise<{ status: ApiStatus; screenshot?: Screenshot }> => {
	interface UpdateScreenshotResponse {
		screenshot: Screenshot;
		status: "ok" | "error";
	}

	try {
		const response = await axios.post(
			`${Config.API_BASE_URL}/v1/screenshot/${screenshot.screenshotId}`,
			{
				screenshot,
			}
		);
		const data: UpdateScreenshotResponse = response.data;
		if (data.status === "ok") {
			return { status: ApiStatus.ok, screenshot: data.screenshot };
		}
	} catch (e) {}
	return { status: ApiStatus.fail };
};

export const Api = { getScreenshot, updateScreenshot };
