// import { Optional } from "../util/types";

export type Optional<T> = T | undefined | null;

export interface Status {
	ok(): boolean;
	reason(): string;
}

class BasicStatus implements Status {
	_status: boolean;
	_reason: string;
	_message: string;
	constructor(status: boolean, reason: string = "", message: string = "") {
		this._status = status;
		this._reason = reason;
		this._message = message;
	}
	ok(): boolean {
		return this._status;
	}
	reason(): string {
		return this._reason;
	}
}

export const MakeErrorStatus = (reason: string = "", message: string = "") => {
	return new BasicStatus(false, reason, message);
};
export const OkStatus = (reason: string = "", message: string = "") => {
	return new BasicStatus(true, reason, message);
};

export function OkStatusOr<T>(value: T) {
	return new StatusOr<T>(OkStatus(), value);
}

export function MakeErrorStatusOr<T>(reason: string) {
	return new StatusOr<T>(MakeErrorStatus(reason), undefined);
}

export class StatusOr<T> {
	_status: Status;
	_value: Optional<T>;
	constructor(status: Status, value: Optional<T>) {
		this._status = status;

		this._value = value;
	}
	ok(): boolean {
		return this._status.ok();
	}
	status(): Status {
		return this._status;
	}
	value(): T {
		return this._value!;
	}
}
