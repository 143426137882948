import Konva from "konva";
import React, { useCallback, useEffect } from "react";
import { Arrow, Circle } from "react-konva";
import { APPEARANCE } from "../../../../lib/appearance/appearance";
import { ArrowAnnotation } from "../../../../lib/model/models";
import { useAppStore } from "../../../../lib/store/app_store";
import { GetLogger } from "../../../../lib/util/simple_logger";
import { IVector2 } from "../../../../lib/util/vector";
import {
	useRegisterAnnotationLayerListener,
	useUIStageProps,
} from "./annotation_helper_util";

const logger = GetLogger("ui_rectangle_annotation");

/**
 * Notes:
 * Arrow points: [x1, y1, x2, y2]
 * x1, y1: tail
 * x2, y2: head
 */

const RESIZER_OFFSET = 2;

interface UIArrowAnnotationProps {
	annotation: ArrowAnnotation;
}

export const UIArrowAnnotationDefaultStyleProps = {
	// strokeWidth: 4,
	// stroke: "red",
	// // fill: "red",
	// opacity: 1,

	pointerLength: 20,
	pointerWidth: 20,
	fill: APPEARANCE.annotationPrimary,
	stroke: APPEARANCE.annotationPrimary,
	strokeWidth: 4,
};

// export const createUIRectangleAnnotationObject = (
// 	pos: Position
// ): Konva.Rect => {
// 	return new Konva.Rect({
// 		x: pos.x,
// 		y: pos.y,
// 		width: 0,
// 		height: 0,
// 		// stroke: "red",
// 		// dash: [5, 10],
// 		visible: true,
// 		listening: false,
// 		...UIRectangleAnnotationDefaultStyleProps,
// 	});
// };
const directionVector = (points: number[]): IVector2 => {
	return {
		x: points[2] - points[0] >= 0 ? 1 : -1,
		y: points[3] - points[1] >= 0 ? 1 : -1,
	};
};

/**
 * Called when Arrow moves. (e.g moves)
 * THe circle needs to be updated too.
 * @param arrowRef C
 */
const updateTransformCircle = (
	arrowRef: Konva.Arrow | null,
	headCircleRef: Konva.Circle | null,
	tailCircleRef: Konva.Circle | null
) => {
	const points = arrowRef?.points() ?? [0, 0, 0, 0];
	logger.log(points);
	const v = directionVector(points);

	tailCircleRef?.x(points[0] + RESIZER_OFFSET * -v.x);
	tailCircleRef?.y(points[1] + RESIZER_OFFSET * -v.y);
	headCircleRef?.x(points[2] + RESIZER_OFFSET * v.x);
	headCircleRef?.y(points[3] + RESIZER_OFFSET * v.y);
};

// const updateArrowAndTransformerOnDrag = (
// 	arrowRef: Konva.Arrow | null,
// 	headCircleRef: Konva.Circle | null,
// 	tailCircleRef: Konva.Circle | null
// ) => {
// 	const points = arrowRef?.points() ?? [0, 0, 0, 0];
// 	const updatedPoints = [
// 		points[0] + (arrowRef?.x() ?? 0),
// 		points[1] + (arrowRef?.y() ?? 0),
// 		points[2] + (arrowRef?.x() ?? 0),
// 		points[3] + (arrowRef?.y() ?? 0),
// 	];
// 	// update the points and reset back to 0.
// 	arrowRef?.points(updatedPoints);
// 	// arrowRef?.x(0);
// 	// arrowRef?.y(0);
// 	updateTransformCircle(arrowRef, headCircleRef, tailCircleRef);
// };

const updateArrowFromTransformCircle = (
	arrowRef: Konva.Arrow | null,
	circleRef: Konva.Circle | null,
	whichCircle: "head" | "tail"
) => {
	if (arrowRef && circleRef) {
		const currentArrowPoints = arrowRef.points();
		let points = [...currentArrowPoints];
		if (whichCircle === "tail") {
			// If tail, then we should use it to calculate the direction vector
			const v = directionVector([
				circleRef.x(),
				circleRef.y(),
				currentArrowPoints[2],
				currentArrowPoints[3],
			]);
			points = [
				circleRef.x() + RESIZER_OFFSET * v.x,
				circleRef.y() + RESIZER_OFFSET * v.y,
				currentArrowPoints[2],
				currentArrowPoints[3],
			];
		} else {
			const v = directionVector([
				currentArrowPoints[0],
				currentArrowPoints[1],
				circleRef.x(),
				circleRef.y(),
			]);
			points = [
				currentArrowPoints[0],
				currentArrowPoints[1],
				circleRef.x() + RESIZER_OFFSET * -v.x,
				circleRef.y() + RESIZER_OFFSET * -v.y,
			];
		}
		arrowRef.points(points);
	}
};

export const UIArrowAnnotation: React.FC<UIArrowAnnotationProps> = (props) => {
	const { annotation } = props;
	const store_updateAnnotations = useAppStore(
		(state) => state.updateAnnotations
	);
	const store_removeAnnotation = useAppStore((state) => state.removeAnnotation);
	const state_arrowRef = React.useRef<Konva.Arrow>(null);
	const [state_isSelected, state_setIsSelected] = React.useState(false);
	const [state_isEnter, state_setIsEnter] = React.useState(false);
	const [state_isDragging, state_setIsDragging] = React.useState(false);
	const state_uiStageProps = useUIStageProps();

	const state_arrowHeadCircleRef = React.useRef<Konva.Circle>(null);
	const state_arrowTailCircleRef = React.useRef<Konva.Circle>(null);

	const handleOnDragStart = (e: Konva.KonvaEventObject<DragEvent>) => {
		state_setIsDragging(true);
	};
	const handleOnDragMove = (e: Konva.KonvaEventObject<DragEvent>) => {
		// while dragging, don't set the position.
		// konva updates the Node.x and Node.y properties. we just need to set them in store
		// @ts-ignore
		logger.log(e.currentTarget.x(), e.currentTarget.y());

		// when drag ends
		// updateArrowAndTransformerOnDrag(
		// 	state_arrowRef?.current,
		// 	state_arrowHeadCircleRef?.current,
		// 	state_arrowTailCircleRef?.current
		// );
		logger.log("moving ");
	};

	const handleOnDragEnd = (e: Konva.KonvaEventObject<DragEvent>) => {
		// Move all the points by the same amount
		const transformer = state_arrowRef?.current?.getTransform();
		const points = state_arrowRef?.current?.points() ?? [0, 0, 0, 0];
		const tailPoint = transformer?.point({
			x: points[0],
			y: points[1],
		});
		const headPoint = transformer?.point({
			x: points[2],
			y: points[3],
		});
		state_arrowRef.current?.points([
			tailPoint?.x ?? 0,
			tailPoint?.y ?? 0,
			headPoint?.x ?? 0,
			headPoint?.y ?? 0,
		]);
		state_arrowRef?.current?.x(0);
		state_arrowRef?.current?.y(0);
		state_arrowRef?.current?.scaleX(1);
		state_arrowRef?.current?.scaleY(1);

		updateTransformCircle(
			state_arrowRef?.current,
			state_arrowHeadCircleRef?.current,
			state_arrowTailCircleRef?.current
		);

		store_updateAnnotations([
			{
				...annotation,
				x: 0,
				y: 0,
				// conva updates the points. we read this property.
				points: state_arrowRef.current?.points() ?? [0, 0, 0, 0],
			} as ArrowAnnotation,
		]);
		state_setIsDragging(false);
	};

	const handleOnMouseEnter = (e: Konva.KonvaEventObject<MouseEvent>) => {
		state_uiStageProps.pushNewStoreStageProps({ cursor: "move" });
		state_setIsEnter(true);

		logger.log("mouse enter");
	};

	const handleOnMouseLeave = (e: Konva.KonvaEventObject<MouseEvent>) => {
		state_uiStageProps.popStoreStageProps();
		state_setIsEnter(false);
	};

	const handleOnClick = (e: Konva.KonvaEventObject<MouseEvent>) => {
		state_setIsSelected(true);
	};

	const handleOnHeadCircleDragMove = (e: Konva.KonvaEventObject<DragEvent>) => {
		// update the points for the arrow.
		updateArrowFromTransformCircle(
			state_arrowRef?.current,
			state_arrowHeadCircleRef?.current,
			"head"
		);
	};

	const handleOnHeadCircleDragStart = (
		e: Konva.KonvaEventObject<DragEvent>
	) => {};

	const handleOnHeadCircleDragEnd = (e: Konva.KonvaEventObject<DragEvent>) => {
		// update the points for the arrow.
		updateArrowFromTransformCircle(
			state_arrowRef?.current,
			state_arrowHeadCircleRef?.current,
			"head"
		);
		store_updateAnnotations([
			{
				...annotation,
				points: state_arrowRef.current?.points() ?? [0, 0, 0, 0],
			} as ArrowAnnotation,
		]);
	};

	const handleOnTailCircleDragMove = (e: Konva.KonvaEventObject<DragEvent>) => {
		// update the points for the arrow.
		updateArrowFromTransformCircle(
			state_arrowRef?.current,
			state_arrowTailCircleRef?.current,
			"tail"
		);
	};

	const handleOnTailCircleDragStart = (
		e: Konva.KonvaEventObject<DragEvent>
	) => {};
	const handleOnTailCircleDragEnd = (e: Konva.KonvaEventObject<DragEvent>) => {
		// update the points for the arrow.
		updateArrowFromTransformCircle(
			state_arrowRef?.current,
			state_arrowTailCircleRef?.current,
			"tail"
		);
		store_updateAnnotations([
			{
				...annotation,
				points: state_arrowRef.current?.points() ?? [0, 0, 0, 0],
			} as ArrowAnnotation,
		]);
	};

	const handleOnCircleEnter = (e: Konva.KonvaEventObject<MouseEvent>) => {
		logger.log("Enered circle");
		state_uiStageProps.pushNewStoreStageProps({ cursor: "crosshair" });
	};
	const handleOnCircleLeave = (e: Konva.KonvaEventObject<MouseEvent>) => {
		state_uiStageProps.popStoreStageProps();
	};

	const handleOnClickOutside = useCallback(
		(e: Konva.KonvaEventObject<MouseEvent>) => {
			// Arrows a bit tricky. So we just use the enter event for now.

			// if the mouse pointer is outside this, then we can deselect.

			if (!state_isEnter && state_isSelected) {
				logger.log("clicking outside...", e.currentTarget);
				state_setIsSelected(false);
			}
		},
		[state_isSelected, state_setIsSelected, state_isEnter]
	);

	const handleOnKeyDownOutside = useCallback(
		(e: KeyboardEvent) => {
			logger.log("key down outside...", e.key);
			if (e.key === "Backspace" && state_isSelected) {
				logger.log("deleting annotation...", props.annotation.annotationId);
				store_removeAnnotation(props.annotation.annotationId);
			}
		},
		[store_removeAnnotation, state_isSelected, props.annotation.annotationId]
	);

	// useEffect(() => {
	// 	// if (state_isSelected) {
	// 	logger.log("Registering Click");
	// 	store_uiCanvasAnnotationLayerRef?.current?.on(
	// 		"click",
	// 		handleOnClickOutside
	// 	);
	// 	const ref = store_uiCanvasAnnotationLayerRef?.current;
	// 	return () => {
	// 		logger.log("removing ...");
	// 		ref?.off("click", handleOnClickOutside);
	// 	};
	// }, [store_uiCanvasAnnotationLayerRef, handleOnClickOutside]);
	useEffect(() => {
		updateTransformCircle(
			state_arrowRef?.current,
			state_arrowHeadCircleRef?.current,
			state_arrowTailCircleRef?.current
		);
	}, []);
	useRegisterAnnotationLayerListener({
		handleOnClickOutside,
		handleOnKeyDown: handleOnKeyDownOutside,
	});

	return (
		<React.Fragment>
			<Arrow
				ref={state_arrowRef}
				listening={true}
				// x={0}
				// y={0}
				// x={annotation.x}
				// y={annotation.y}
				// rotation={annotation.rotation}
				points={annotation.points}
				// width={annotation.width}
				// height={annotation.height}
				// fill="red"
				// opacity={0}
				// stroke={"red"}
				// strokeWidth={2}
				{...UIArrowAnnotationDefaultStyleProps}
				// style={{}}
				// fill = {isEnter ? "blue" : "red"}
				draggable
				// dash={[5, 10]}
				// onClick={handleOnEnter}

				onClick={handleOnClick}
				onMouseEnter={handleOnMouseEnter}
				onMouseLeave={handleOnMouseLeave}
				onDragStart={handleOnDragStart}
				onDragMove={handleOnDragMove}
				onDragEnd={handleOnDragEnd}
				// onTransformEnd={handleOnTransformEnd}
			/>

			<Circle
				visible={state_isSelected && !state_isDragging}
				ref={state_arrowHeadCircleRef}
				listening={true}
				// x={annotation.points[0] - RESIZER_OFFSET}
				// y={annotation.points[1] - RESIZER_OFFSET}
				radius={5}
				fill={"white"}
				stroke={"blue"}
				strokeWidth={1}
				opacity={0.5}
				draggable={true}
				onEnter={handleOnCircleEnter}
				onLeave={handleOnCircleLeave}
				onDragStart={handleOnHeadCircleDragStart}
				onDragEnd={handleOnHeadCircleDragEnd}
				onDragMove={handleOnHeadCircleDragMove}
			/>
			<Circle
				visible={state_isSelected && !state_isDragging}
				ref={state_arrowTailCircleRef}
				listening={true}
				// x={annotation.points[2] + RESIZER_OFFSET}
				// y={annotation.points[3] + RESIZER_OFFSET}
				radius={5}
				fill={"white"}
				stroke={"blue"}
				strokeWidth={1}
				onEnter={handleOnCircleEnter}
				onLeave={handleOnCircleLeave}
				opacity={0.5}
				draggable={true}
				onDragStart={handleOnTailCircleDragStart}
				onDragMove={handleOnTailCircleDragMove}
				onDragEnd={handleOnTailCircleDragEnd}
			/>
		</React.Fragment>
	);
};
