import Konva from "konva";
import React, { useCallback, useEffect } from "react";
import { Group, Rect, Text, Transformer } from "react-konva";
import { APPEARANCE } from "../../../../lib/appearance/appearance";
import { Position, Size, TextAnnotation } from "../../../../lib/model/models";
import { useAppStore } from "../../../../lib/store/app_store";
import { UIStageProps } from "../../../../lib/store/app_store.interface";
import { GetLogger } from "../../../../lib/util/simple_logger";
import { UICanvasUtil } from "../ui_canvas_util";
// import { getMousePosition } from "../ui_canvas_util";
import { useRegisterAnnotationLayerListener } from "./annotation_helper_util";
import { EditableTextInput } from "./components/editable_text_input";

const logger = GetLogger("ui_rectangle_annotation");

const MIN_WIDTH = 2;
const BOUNDING_RECT_SIZE_PADDING = 12;

interface UITextAnnotationProps {
	annotation: TextAnnotation;
}

export const UITextAnnotationDefaultStyleProps = {
	fontSize: 20,
	fill: APPEARANCE.annotationPrimary,
	fontStyle: "bold",
};

export const UITextAnnotation: React.FC<UITextAnnotationProps> = (props) => {
	const { annotation } = props;
	const store_updateAnnotations = useAppStore(
		(state) => state.updateAnnotations
	);
	const store_removeAnnotation = useAppStore((state) => state.removeAnnotation);
	const store_uiStageProps = useAppStore((state) => state.uiStageProps);
	const store_uiCanvasAnnotationLayerRef = useAppStore(
		(state) => state.uiCanvasAnnotationLayerRef
	);
	const store_updateUIStageProps = useAppStore(
		(state) => state.updateUIStageProps
	);
	const [state_prevUIStageProps, state_setPrevUIStageProps] =
		React.useState<UIStageProps | null>(store_uiStageProps);
	const state_rectRef = React.useRef<Konva.Rect>(null);
	const state_currentTextRef = React.useRef<Konva.Text>(null);
	const state_transformerRef = React.useRef<Konva.Transformer>(null);
	const state_groupRef = React.useRef<Konva.Group>(null);
	const [state_isEditorEnabled, state_setIsEditorEnabled] =
		React.useState(false);
	const [state_isSelected, state_setIsSelected] = React.useState(false);

	const [state_editorText, state_setEditorText] = React.useState<string>(
		props.annotation.text
	);
	const [state_isTransforming, state_setIsTransforming] = React.useState(false);

	const handleOnDragMove = (e: Konva.KonvaEventObject<DragEvent>) => {
		// while dragging, don't set the position.
		// konva updates the Node.x and Node.y properties. we just need to set them in store
		// when drag ends
		updateBoundingRectange(state_currentTextRef.current);
	};

	const handleOnDragEnd = (e: Konva.KonvaEventObject<DragEvent>) => {
		const layerPos =
			store_uiCanvasAnnotationLayerRef?.current?.getRelativePointerPosition();
		logger.log("drag move", {
			client: [e.evt.clientX, e.evt.clientY],
			pos: [e.evt.x, e.evt.y],
			target: [e.target.x(), e.target.y()],
			layer: [layerPos?.x, layerPos?.y],
		});
		updateBoundingRectange(state_currentTextRef.current);
		store_updateAnnotations([
			{ ...annotation, x: e.target.x(), y: e.target.y() },
		]);
	};
	const handleOnDragStart = (e: Konva.KonvaEventObject<DragEvent>) => {};
	const handleOnMouseEnter = (e: Konva.KonvaEventObject<MouseEvent>) => {
		state_setPrevUIStageProps(store_uiStageProps);
		store_updateUIStageProps({ cursor: "move" });
		logger.log("mouse enter");
	};
	const handleOnMouseLeave = (e: Konva.KonvaEventObject<MouseEvent>) => {
		if (state_prevUIStageProps) {
			store_updateUIStageProps(state_prevUIStageProps);
		}
	};

	const updateBoundingRectange = (referenceRef: Konva.Text | null) => {
		state_groupRef?.current?.setAttrs({
			x: referenceRef?.x() ?? 0 - BOUNDING_RECT_SIZE_PADDING / 2,
			y: referenceRef?.y() ?? 0 - BOUNDING_RECT_SIZE_PADDING / 2,
			width: referenceRef?.width() ?? 0 + BOUNDING_RECT_SIZE_PADDING,
			height: referenceRef?.height() ?? 0 + BOUNDING_RECT_SIZE_PADDING,
		});
		// groups don't have an intrinsic width and height. They try to
		// "fit thier contnet. Hence we need to set the size of rect manually"
		state_rectRef?.current?.setAttrs({
			width: referenceRef?.width() ?? 0 + BOUNDING_RECT_SIZE_PADDING,
			height: referenceRef?.height() ?? 0 + BOUNDING_RECT_SIZE_PADDING,
		});
		state_groupRef?.current?.rotation(referenceRef?.rotation() ?? 0);
	};

	const handleOnTransform = (e: Konva.KonvaEventObject<DragEvent>) => {
		logger.log("here", e.target.rotation());
		const scalex = state_currentTextRef?.current?.scaleX() ?? 1;
		const width = Math.max(
			state_currentTextRef?.current?.width() ?? 0 * scalex,
			MIN_WIDTH
		);

		state_currentTextRef?.current?.scaleX(1);
		state_currentTextRef?.current?.scaleY(1);
		state_currentTextRef?.current?.width(Math.max(width * scalex, MIN_WIDTH));
		// No need to update the boinding rectangle since we don't make it
		// visible on transofrmation.
	};

	const handleOnTransformStart = (e: Konva.KonvaEventObject<DragEvent>) => {
		logger.log("Transform started");
		state_setIsTransforming(true);
	};

	const handleOnTransformEnd = (e: Konva.KonvaEventObject<DragEvent>) => {
		const scalex = state_currentTextRef?.current?.scaleX() ?? 1;
		const height = state_currentTextRef?.current?.height() ?? 1;
		const width = Math.max(
			state_currentTextRef?.current?.width() ?? 0 * scalex,
			MIN_WIDTH
		);
		updateBoundingRectange(state_currentTextRef?.current);
		store_updateAnnotations([
			{
				...annotation,
				// get current roation from transformer.
				rotation: e.target.rotation(),
				// get current height of text
				height: height,
				// get current width of text.
				width: width,
			},
		]);
		state_setIsTransforming(false);
	};

	const handleOnClickOutside = useCallback(
		(e: Konva.KonvaEventObject<MouseEvent>) => {
			// if (state_groupRef.current.)
			// logger.log("clicking outside...", e.currentTarget);
			// get mouseposition in the layer.
			const mousePosition = UICanvasUtil.getMousePosition(
				e,
				store_uiCanvasAnnotationLayerRef?.current
			);
			// since we are usng a gropu, the position would be the
			// group position. But to standardize our method, let's use the positoin
			// of the bounding rectangle with respet to the layer. This is
			// the group position + the rectangle (relative) position.
			const boundingRectPos: Position = {
				x:
					(state_groupRef?.current?.x() ?? 0) +
					(state_rectRef.current?.x() ?? 0),
				y:
					(state_groupRef?.current?.y() ?? 0) +
					(state_rectRef.current?.y() ?? 0),
			};
			const boundingRectSize: Size = {
				width: state_rectRef?.current?.width() ?? 0,
				height: state_rectRef?.current?.height() ?? 0,
			};
			// if the mouse pointer is outside this, then we can deselect.
			const x1 = boundingRectPos.x;
			const y1 = boundingRectPos.y;
			const x2 = boundingRectPos.x + boundingRectSize.width;
			const y2 = boundingRectPos.y + boundingRectSize.height;
			const isInside =
				mousePosition.x >= x1 &&
				mousePosition.x <= x2 &&
				mousePosition.y >= y1 &&
				mousePosition.y <= y2;

			if (!isInside && state_isSelected) {
				logger.log("clicking outside...", e.currentTarget);
				state_setIsSelected(false);
				state_setIsEditorEnabled(false);
			}
		},
		[
			state_isSelected,
			state_setIsSelected,
			state_setIsEditorEnabled,
			store_uiCanvasAnnotationLayerRef,
			state_groupRef,
		]
	);
	const handleOnKeyDownOutside = useCallback(
		(e: KeyboardEvent) => {
			logger.log("key down outside...", e.key);
			if (e.key === "Backspace" && state_isSelected && !state_isEditorEnabled) {
				logger.log("deleting annotation...", props.annotation.annotationId);
				store_removeAnnotation(props.annotation.annotationId);
			}
		},
		[
			store_removeAnnotation,
			state_isSelected,
			props.annotation.annotationId,
			state_isEditorEnabled,
		]
	);

	const handleOnClick = (e: Konva.KonvaEventObject<MouseEvent>) => {
		state_setIsSelected(true);
		state_setIsEditorEnabled(false);
		// document.addEventListener("click", handleOnClickOutside);
		// state_setIsListeningToOnClickOutside(true);
		// TODO: Once selected, bind an outside click listener to deselect.
	};

	const handleOnDoubleClick = (e: Konva.KonvaEventObject<MouseEvent>) => {
		state_setIsEditorEnabled(true);
	};

	const handleOnToggleEdit = () => {
		if (state_isEditorEnabled) {
			state_currentTextRef?.current?.text(state_editorText);
			store_updateAnnotations([
				{
					...props.annotation,
					text: state_editorText,
					width: state_currentTextRef?.current?.width() ?? MIN_WIDTH,
				},
			]);
		}
		state_setIsEditorEnabled(!state_isEditorEnabled);
	};

	useEffect(() => {
		if (state_currentTextRef?.current) {
			state_transformerRef.current?.nodes([state_currentTextRef?.current]);
		}
	}, [state_currentTextRef, state_transformerRef]);

	useEffect(() => {
		logger.log("updating Rectangle Effect");
		// update bounding rectangle when anything changes in prps.
		updateBoundingRectange(state_currentTextRef.current);
	}, [props.annotation]);

	// useEffect(() => {
	// 	// if (state_isSelected) {
	// 	logger.log("Registering Click");
	// 	store_uiCanvasAnnotationLayerRef?.current?.on(
	// 		"click",
	// 		handleOnClickOutside
	// 	);
	// 	const ref = store_uiCanvasAnnotationLayerRef?.current;
	// 	return () => {
	// 		logger.log("removing ...");
	// 		ref?.off("click", handleOnClickOutside);
	// 	};
	// }, [store_uiCanvasAnnotationLayerRef, handleOnClickOutside]);
	useRegisterAnnotationLayerListener({
		handleOnClickOutside,
		handleOnKeyDown: handleOnKeyDownOutside,
	});

	/**
	 * Notes when using Group. When using Groups, the x() and y()
	 * of the children are relative to the origin of the group.
	 * for drag events, we use the event.target.x() and event.target.y().
	 * This works. But for transformation, do be careful.
	 */
	return (
		<React.Fragment>
			<Group
				ref={state_groupRef}
				x={annotation.x}
				y={annotation.y}
				rotation={annotation.rotation}
			>
				<Rect
					// visible={!state_isTransforming && state_isSelected}
					visible={false}
					ref={state_rectRef}
					x={0}
					y={0}
					opacity={1}
					stroke="orange"
					strokeWidth={1}
				/>

				{state_isEditorEnabled && (
					<EditableTextInput
						x={0} // relative to the group
						y={0} // relative to the grop
						width={annotation.width ?? 0}
						// height={annotation.height ?? 0}
						text={state_editorText}
						onEditTextChange={(text) => state_setEditorText(text)}
						onToggleEdit={handleOnToggleEdit}
					/>
				)}
			</Group>
			<Text
				visible={!state_isEditorEnabled}
				ref={state_currentTextRef}
				scaleX={1}
				scaleY={1}
				text={annotation.text}
				{...UITextAnnotationDefaultStyleProps}
				listening={true}
				x={annotation.x}
				y={annotation.y}
				rotation={annotation.rotation}
				draggable
				onClick={handleOnClick}
				onDblClick={handleOnDoubleClick}
				onMouseEnter={handleOnMouseEnter}
				onMouseLeave={handleOnMouseLeave}
				onDragStart={handleOnDragStart}
				onDragMove={handleOnDragMove}
				onDragEnd={handleOnDragEnd}
				onTransformStart={handleOnTransformStart}
				onTransform={handleOnTransform}
				onTransformEnd={handleOnTransformEnd}
			/>
			<Transformer
				ref={state_transformerRef}
				visible={state_isSelected}
				padding={5}
				enabledAnchors={["middle-left", "middle-right"]}
				boundBoxFunc={(oldBox: any, newBox: any) => {
					if (newBox.width < MIN_WIDTH) {
						return oldBox;
					}
					return newBox;
				}}
			/>
		</React.Fragment>
	);
};
