import Konva from "konva";
import React, { useState } from "react";
import { Image as KonvaImage, Layer, Rect, Stage } from "react-konva";
import useImage from "use-image";
import { MEDIA_CONSTANTS } from "../../lib/constants/constants_media";
import { Position, Scale, Screenshot, Size } from "../../lib/model/models";
import { GetLogger } from "../../lib/util/simple_logger";

const logger = GetLogger("cropper_canvas");
const MIME_TYPE = MEDIA_CONSTANTS.MIME_TYPE;
const MIME_TYPE_EXT = MEDIA_CONSTANTS.MIME_TYPE_EXT;
const TEST_MODE = false;
const EXPORT_PIXEL_RATIO = 1;
const MAX_SIZE: Size = { width: 1429, height: 920 };
// other options: // { width: 2000, height: 1000 }; //{ width: 2242, height: 1247 };

export interface CropResult {
	x: number;
	y: number;
	width: number;
	height: number;
	dataImageString: string;
}

interface CanvasProps {
	screenshot: Screenshot;
	canvasWidth: number;
	canvasHeight: number;
	onCrop: (cropResult: CropResult) => void;
}

type Mode = "drawing" | "idle";

const BORDER_BOX_PROPS = {
	x: 0,
	y: 0,
	width: 0,
	height: 0,
	fill: "grey",
	// stroke: "red",
	// dash: [5, 10],
};
const OPACITY_VALUE = 0.5;

export const downloadImageUrl = (url: string, name: string) => {
	const a = document.createElement("a");
	a.href = url;
	a.download = name;
	a.click();
};

const generateCropResult1 = (
	r: Konva.Rect,
	sX: number,
	sY: number,
	sourceImage: HTMLImageElement | undefined,
	canvasWidth: number,
	canvasHeight: number
): CropResult => {
	let jpeg = new Konva.Image({
		image: sourceImage,
		x: sX,
		y: sY,
	});

	// convert to image coordinate.
	let scale: Scale = { x: 1, y: 1 };
	if (sourceImage?.width && sourceImage.height) {
		scale.x = sourceImage!.width / canvasWidth;
		scale.y = sourceImage.height / canvasHeight;
	}
	const cropWidth = r.width();
	const cropHeight = r.height();
	console.log("usince scalex ", [scale.x, scale.y]);
	console.log("Image size ||", [sourceImage?.width, sourceImage?.height]);
	console.log("Crop Width ||", [cropWidth, cropHeight]);

	jpeg.cropX(r.x() * scale.x);
	jpeg.cropY(r.y() * scale.y);
	jpeg.cropWidth(cropWidth * scale.x);
	jpeg.cropHeight(cropHeight * scale.y);
	jpeg.width(r.width());
	jpeg.height(r.height());
	// jpeg.siz
	const aspectRatio = cropWidth / cropHeight;
	const pixelSize = jpeg.pixelSize();
	logger.log("pixelSize", pixelSize);
	const actualCropWidth = cropWidth * scale.x;
	const actualCropHeight = cropHeight * scale.y;
	const pixelRatio = actualCropWidth > 1000 || actualCropHeight > 1000 ? 1 : 1;
	const url = jpeg.toDataURL({
		mimeType: MIME_TYPE,
		quality: 1.0,
		pixelRatio: pixelRatio,
	});

	// Call callback that CropImage is ready.
	const cropResult: CropResult = {
		dataImageString: url,
		width: cropWidth * pixelRatio,
		height: cropHeight * pixelRatio,
		x: r.x(),
		y: r.y(),
	};
	return cropResult;
};

const getImageSizeInMB = (width: number, height: number) => {
	return (width * height * 3) / (1024 * 1024);
};

const computeDesiredDimension2 = (
	currentWidth: number,
	currentHeight: number
) => {
	if (currentWidth > MAX_SIZE.width) {
		const aspectRatio = currentHeight / currentWidth;
		const newWidth = MAX_SIZE.width;
		const newHeight = Math.min(newWidth * aspectRatio, MAX_SIZE.height);
		return { width: newWidth, height: newHeight };
	} else if (currentHeight > MAX_SIZE.height) {
		// clip to max height.
		return { width: currentWidth, height: MAX_SIZE.height };
	}
	return { width: currentWidth, height: currentHeight };
};

const calculateUrlImageSizeInMB = async (
	url: string
): Promise<{ sizeMB: number; size: Size }> => {
	return new Promise((resolve, reject) => {
		Konva.Image.fromURL(url, (img: Konva.Image) => {
			const width = img.width();
			const height = img.height();
			const sizeMB = getImageSizeInMB(width, height);
			resolve({ sizeMB, size: { width, height } });
		});
	});
};

const generateCropResult2 = async (
	initialCropResult: CropResult
): Promise<CropResult> => {
	return new Promise((resolve, reject) => {
		// set new width and height.
		Konva.Image.fromURL(
			initialCropResult.dataImageString,
			(img: Konva.Image) => {
				const widthBefore = img.width();
				const heightBefore = img.height();

				logger.log(
					"Cropped Size in MB Befor ",
					getImageSizeInMB(widthBefore, heightBefore)
				);
				logger.log("Cropped Size Before ", [widthBefore, heightBefore]);

				const newDimension = computeDesiredDimension2(
					widthBefore,
					heightBefore
				);
				// Set image dimension.
				img.x(0);
				img.y(0);
				img.width(newDimension.width);
				img.height(newDimension.height);

				// Some displays.
				const currentWidth = img.width();
				const currentHeight = img.height();
				logger.log("Cropped Size After ", [currentWidth, currentHeight]);
				logger.log(
					"Coprred Size MB ",
					getImageSizeInMB(currentWidth, currentHeight)
				);

				const pixelRatio = EXPORT_PIXEL_RATIO;
				// Generate new URL.
				const tempurl = img?.toDataURL({
					mimeType: MIME_TYPE,
					quality: 1.0,
					pixelRatio,
				});

				const croppedResult: CropResult = {
					dataImageString: tempurl ?? "",
					width: newDimension.width,
					height: newDimension.height,
					x: initialCropResult.x,
					y: initialCropResult.y,
				};
				resolve(croppedResult);
			},
			() => {
				reject("Error in generating cropped image");
			}
		);
	});
};

const displayImageUrl = async (
	url: string,
	displayRef: Konva.Image | null
): Promise<void> => {
	return new Promise((resolve, reject) => {
		Konva.Image.fromURL(url, (img: Konva.Image) => {
			const ar = img.height() / img.width();
			const newWidth = img.width() * 2.5;
			displayRef?.image(img.image());
			displayRef?.width(newWidth);
			displayRef?.height(newWidth * ar);
			resolve();
		});
	});
};

export const CropperCanvas: React.FC<CanvasProps> = (props) => {
	let sX = 0,
		sY = 0;
	const stageRef = React.useRef(null);
	// TODO: Can we reduce the size of this image?
	const [sourceImage] = useImage(
		props.screenshot.dataImageString ?? "",
		"anonymous"
	);
	const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
	const [croppedImage] = useImage(croppedImageUrl ?? "", "anonymous");

	// const [loading, setloading] = useState(true);
	const [posStart, setposStart] = useState<Position>({ x: 0, y: 0 });
	// const [posNow, setposNow] = useState<Position>({ x: 0, y: 0 });
	const [mode, setmode] = useState<Mode>("idle");

	const imageRef = React.useRef<Konva.Image>(null);
	// const croppedImageRef = React.useRef<Konva.Image>(null);
	const croppedImageDisplayRef = React.useRef<Konva.Image>(null);
	const layerRef = React.useRef<Konva.Layer>(null);

	const containerR1Ref = React.useRef<any>(null);
	// const containerR2Ref = React.useRef<any>(null);
	const state_topRectRef = React.useRef<Konva.Rect>(null);
	const state_bottomRectRef = React.useRef<Konva.Rect>(null);
	const state_leftRectRef = React.useRef<Konva.Rect>(null);
	const state_rightRectRef = React.useRef<Konva.Rect>(null);
	const state_croppingRectRef = React.useRef<Konva.Rect>(null);

	/**
	 * Sets the state of posStart and posNow for tracking the coordinates of the cropping rectangle
	 * @param {Object} posIn - Coordinates of the pointer when MouseDown is fired
	 */
	function startDrag(posIn: Position) {
		setposStart({ x: posIn.x, y: posIn.y });
		// setposNow({ x: posIn.x, y: posIn.y });
	}

	/**
	 * Updates the state accordingly when the MouseMove event is fired
	 * @param {Object} posIn - Coordiantes of the current position of the pointer
	 */
	// TODO: Fix this function.
	// This function redraws the 4 bounding Rectangles that make up the cropping rectangle.

	function updateDrag(posIn: Position) {
		// setposNow({ x: posIn.x, y: posIn.y });
		const posRect = reverse(posStart, posIn);
		// first rectangle
		state_topRectRef?.current?.x(0);
		state_topRectRef?.current?.y(0);
		state_topRectRef?.current?.width(props.canvasWidth);
		state_topRectRef?.current?.height(posRect.y1);
		// state_topRectRef?.current?.opacity(opacity);

		// left rectangle
		state_leftRectRef?.current?.x(0);
		state_leftRectRef?.current?.y(posRect.y1);
		state_leftRectRef?.current?.width(posRect.x1);
		state_leftRectRef?.current?.height(posRect.y2 - posRect.y1);
		// state_leftRectRef?.current?.opacity(opacity);

		// bottom rectangle
		state_bottomRectRef?.current?.x(0);
		state_bottomRectRef?.current?.y(posRect.y2);
		state_bottomRectRef?.current?.width(props.canvasWidth);
		state_bottomRectRef?.current?.height(props.canvasHeight - posRect.y2);
		// state_bottomRectRef?.current?.opacity(opacity);

		// right rectangle
		state_rightRectRef?.current?.x(posRect.x2);
		state_rightRectRef?.current?.y(posRect.y1);
		state_rightRectRef?.current?.width(props.canvasWidth - posRect.x2);
		state_rightRectRef?.current?.height(posRect.y2 - posRect.y1);
		// state_rightRectRef?.current?.opacity(opacity);

		// cropping rectangle
		state_croppingRectRef?.current?.x(posRect.x1);
		state_croppingRectRef?.current?.y(posRect.y1);
		state_croppingRectRef?.current?.width(posRect.x2 - posRect.x1);
		state_croppingRectRef?.current?.height(posRect.y2 - posRect.y1);
	}

	/**
	 * Reverse coordinates if dragged left or up
	 * @param {Object} r1 - Coordinates of the starting position of cropping rectangle
	 * @param {Object} r2 - Coordinates of the current position of cropping rectangle
	 */
	function reverse(r1: Position, r2: Position) {
		let r1x = r1.x,
			r1y = r1.y,
			r2x = r2.x,
			r2y = r2.y,
			d;
		if (r1x > r2x) {
			d = Math.abs(r1x - r2x);
			r1x = r2x;
			r2x = r1x + d;
		}
		if (r1y > r2y) {
			d = Math.abs(r1y - r2y);
			r1y = r2y;
			r2y = r1y + d;
		}
		return { x1: r1x, y1: r1y, x2: r2x, y2: r2y }; // return the corrected rect.
	}

	/**
	 * Crops the image and saves it in jpeg format
	 * @param {Konva.Rect} r - Ref of the cropping rectangle
	 */
	const setCrop = async (r: Konva.Rect) => {
		const result1 = generateCropResult1(
			r,
			sX,
			sY,
			sourceImage,
			props.canvasWidth,
			props.canvasHeight
		);
		const result2 = await generateCropResult2(result1);

		// MEasure size of image in result.
		const ss = await calculateUrlImageSizeInMB(result2.dataImageString);
		logger.log("Actual Image Download Size: ", ss);
		await displayImageUrl(
			result2.dataImageString,
			croppedImageDisplayRef.current
		);

		downloadImageUrl(result2.dataImageString, "cropped" + MIME_TYPE_EXT);

		props.onCrop(result2);
	};

	// React.useEffect(() => {}, []);

	const handleOnMouseUp = (e: Konva.KonvaEventObject<MouseEvent>) => {
		setmode("idle");
		if (state_croppingRectRef.current) setCrop(state_croppingRectRef.current);
	};

	const handleOnMouseMove = (e: Konva.KonvaEventObject<MouseEvent>) => {
		if (mode === "drawing") {
			// @ts-ignore
			updateDrag({ x: e.evt.layerX, y: e.evt.layerY });
		}
	};

	const handleOnMouseDown = (e: Konva.KonvaEventObject<MouseEvent>) => {
		setmode("drawing");
		// @ts-ignore
		startDrag({ x: e.evt.layerX, y: e.evt.layerY });
		// no need to set image opacity.
		// imageRef.current!.opacity(0.8);
	};

	return (
		<React.Fragment>
			<Stage
				ref={stageRef}
				width={props.canvasWidth}
				height={props.canvasHeight}
				scaleX={1}
				scaleY={1}
			>
				<Layer ref={layerRef}>
					<KonvaImage
						visible={!TEST_MODE}
						ref={imageRef}
						image={sourceImage}
						listening={false}
						x={sX}
						y={sY}
						width={props.canvasWidth}
						height={props.canvasHeight}
					/>
					<Rect
						ref={containerR1Ref}
						{...{
							x: 0,
							y: 0,
							width: props.canvasWidth,
							height: props.canvasHeight,
							fill: "white",
							opacity: 0,
						}}
						onMouseDown={handleOnMouseDown}
						onMouseMove={handleOnMouseMove}
						onMouseUp={handleOnMouseUp}
					/>

					{/* <Group listening={false} ref={groupRef}></Group> */}
				</Layer>
				<Layer>
					<Rect
						visible={mode === "drawing" ? true : false}
						ref={state_croppingRectRef}
						listening={false}
						{...{
							x: 0,
							y: 0,
							width: 0,
							height: 0,
							stroke: "red",
							dash: [5, 10],
						}}
					/>
					<Rect
						ref={state_topRectRef}
						listening={false}
						{...BORDER_BOX_PROPS}
						opacity={mode === "drawing" ? OPACITY_VALUE : 0}
						// opacity={mode === "drawing" ? 0.5 : 0}
					/>
					<Rect
						ref={state_leftRectRef}
						listening={false}
						{...BORDER_BOX_PROPS}
						opacity={mode === "drawing" ? OPACITY_VALUE : 0}
					/>
					<Rect
						ref={state_bottomRectRef}
						visible={true}
						listening={false}
						{...BORDER_BOX_PROPS}
						opacity={mode === "drawing" ? OPACITY_VALUE : 0}
					/>
					<Rect
						ref={state_rightRectRef}
						listening={false}
						{...BORDER_BOX_PROPS}
						opacity={mode === "drawing" ? OPACITY_VALUE : 0}
					/>
					{/* <Rect
						fill="red"
						opacity={0.5}
						height={50}
						width={50}
						onClick={handleDoSomething}
					/> */}
				</Layer>
				<Layer visible={TEST_MODE}>
					<KonvaImage
						// visible={TEST_MODE}
						ref={croppedImageDisplayRef}
						image={croppedImage}
						x={sX}
						y={sY}
					/>
				</Layer>
			</Stage>
		</React.Fragment>
	);
};
