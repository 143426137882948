import { Box, ChakraProvider } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FullPageLayout } from "../../lib/components/layout";
import { CommonModal } from "../../lib/components/modals/common_modal";
import { ModalIds } from "../../lib/components/modals/common_modal.interface";
import { Config } from "../../lib/config/config";
import { Screenshot } from "../../lib/model/models";
import { useAppStore } from "../../lib/store/app_store";
import { GetLogger } from "../../lib/util/simple_logger";
import { Api } from "./api";
import { CropResult } from "./cropper_canvas";
import { ERROR_REASON_SIZE_LIMIT_EXCEEDED } from "./model";
import { ScreenshotEditor } from "./screenshot_editor";

const logger = GetLogger("app_screenshot");

async function getCurrentTab() {
	let queryOptions = { active: true, lastFocusedWindow: true };
	// `tab` will either be a `tabs.Tab` instance or `undefined`.
	let [tab] = await chrome.tabs.query(queryOptions);
	return tab;
}

const getScreenshotDataIdFromUrl = () => {
	const location = window.location;
	const urlParams = new URLSearchParams(location.search);
	return urlParams.get("id");
};

const getScreenshotDataUrlFromStorage = async (
	key: string
): Promise<Screenshot | null> => {
	const values = await chrome.storage.local.get([key]);
	console.log("Value currently is ", values);
	return (values[key] as Screenshot) ?? null;
	// 	console.log("Value currently is " + result.key);
	// });
};

interface AppScreenshotProps {
	screenshot: Screenshot | null;
	onNavigateToViewer: (screenshotId: string) => void;
}

export const AppScreenshot: React.FC<AppScreenshotProps> = (props) => {
	const setActiveModal = useAppStore((store) => store.setActiveModal);

	const doPerformSaveScreenshot = async (
		shot2: Screenshot,
		navigateToViewer: (url: string) => void
	) => {
		const response = await Api.putScreenshot(shot2);
		if (response.ok()) {
			navigateToViewer(response.value().screenshotId ?? "");
		} else {
			logger.log("reason got ", response.status().reason());
			if (response.status().reason() === ERROR_REASON_SIZE_LIMIT_EXCEEDED) {
				alert("Image size limit exceeded. Please select a smaller region.");
			}
			// TODO: Display Modal asking user to select a smaller region.
		}

		logger.log("Response rceived ", response);
	};

	const handleOnCrop = async (cropResult: CropResult) => {
		logger.log("Crop Result", cropResult);
		const shot2: Screenshot = {
			...props.screenshot,
			dataImageString: cropResult.dataImageString,
			imageHeight: cropResult.height,
			imageWidth: cropResult.width,
		};
		// TODO: send crop result to backend
		setActiveModal({
			id: ModalIds.BackgroundLoading,
			payload: {
				callback: async () => {
					await doPerformSaveScreenshot(shot2, props.onNavigateToViewer);
				},
			},
		});
	};

	return (
		<ChakraProvider>
			<React.Fragment>
				<FullPageLayout>
					{props.screenshot ? (
						<ScreenshotEditor
							screenshot={props.screenshot}
							onCrop={handleOnCrop}
						/>
					) : (
						// TODO: Show home page instead. Lol.
						<Box> Nothing to show </Box>
					)}
				</FullPageLayout>
				<CommonModal />
			</React.Fragment>
		</ChakraProvider>
	);
};

export const AppScreenshotContainer = () => {
	const [screenshot, setScreenshot] = useState<Screenshot | null>({});

	const initialize = async () => {
		// console.log("Hello", sq);
		const currentTab = await getCurrentTab();
		const dataId = getScreenshotDataIdFromUrl();
		if (!dataId) {
			return;
		}
		const data = await getScreenshotDataUrlFromStorage(dataId);
		if (data) {
			setScreenshot(data);
		}
		console.log("Current tab", currentTab);
		console.log("Current location ", window.location);
		console.log("data id", dataId);
		console.log("Data", data);
	};

	const handleOnNavigateToViewer = (screenshotId: string) => {
		window.location.href = `${Config.WEB_APP_DOMAIN}/${screenshotId}`;
	};

	useEffect(() => {
		initialize();
	}, []);

	return (
		<AppScreenshot
			screenshot={screenshot}
			onNavigateToViewer={handleOnNavigateToViewer}
		/>
	);
};
